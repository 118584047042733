<script setup lang="ts">
const items = ref([]);
// const loading = ref(false)
async function getNaviBar () {
  try {
    // await nextTick()
    const { data } = await useFetch('/api/front/jm-navigation-bar')
    if(Array.isArray(data.value?.data)){
      items.value = data.value?.data
    }
  } catch (error) {
    console.error(error)
  }
}

const togglePopoverShow = (item, visiable) => {
  item.popover = visiable;
}

getNaviBar()
onMounted(() => {
  // getNaviBar()
})
// 完成一次导航主动关闭所有导航菜单
const allPopvers = ref(); 
const router = useRouter();
watch(router.currentRoute, () => {
  if (Array.isArray(allPopvers.value)) {
    for(const pop of allPopvers.value) {
      if (pop && 'hide' in pop) {
        pop.hide();
      }
    }
  }
});
const popoverModal = ref(true)
</script>
<template>
  <nav class="menu_nav_wrap_outter">
    <div class="pc-container">
      <div class="menu_block">
        <ul class="flex jc_ct">
          <!-- <el-skeleton v-if="pending" animated :rows="1" /> -->
          <li v-for="(item, index) of items" :key="item.id" class="menu_block_item flex ai_ct jc_ct">
            <el-popover ref="allPopvers" width="100%" :hide-after="1" :transition="'notransition'" style="z-index: 999;" :popper-class="index == 0 ? 'menu_item_popover menu_item_popover_margin' : 'menu_item_popover'"
              placement="bottom-start" :trigger="item?.children ? 'hover' : ''" :modal="true" :offset="0" :visible-arrow="false" @show="togglePopoverShow(item, true)" @hide="togglePopoverShow(item, false)">
              <template #reference>
                <NuxtLink v-if="item.navigationLink" :to="item.navigationLink" class="menu_block_item_span" :class="item.popover ? 'active': ''">{{ item.title }}</NuxtLink>
                <span v-else class="menu_block_item_span" :class="item.popover ? 'active': ''">{{ item.title }}</span>
              </template>
              <client-only>
                <NavigationBarContent :list="item.children" />
              </client-only>
            </el-popover>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<style scoped lang="scss">

.menu_item_popover {
  left: 0 !important;
  // box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12) inset;
}
// .menu_item_popover_margin .menu_ul{
//   margin-left: -260px;
// }
.menu_item_popover .el-popper__arrow{
  display: none!important;
}
.menu_nav_wrap_outter {
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgba($color: #000000, $alpha: 0.1);
  position: relative;
}


.menu_block {
  // margin: 0 auto;
  width: 100%;
}

.menu_block_item {
  font-weight: 500;
  font-size: 13px;
  height: 50px;
  cursor: pointer;
  a.menu_block_item_span {
    text-decoration: none;
  }

  // line-height: 50px;
  .menu_block_item_span {

    color: #333333;
    font-family: gothic;
    font-size: 14px;
    height: 100%;
    align-items: center;
    display: flex;
    padding: 0 15px;
    &:hover {
      color: #04A9C7;
      font-weight: bold;
    }
  }

  .menu_block_item_span.active {
      color: #04A9C7;
      font-weight: bold;
  }

}

.sm-site-header {
  .menu-top-center {
    .pc-container {
      width: 1038px;
      height: 48px;
      margin: auto;
      display: flex;
      align-items: center;
      // @media (max-width: 1600px) {
      //   width: 800px;
      // }
      // @media (max-width: 1400px) {
      //   width: 700px;
      // }
    }
    .menu_block_item {
  
    }
  }
}
@media (max-width: 1200px) {
	.menu_block_item_span{
    padding: 0 10px!important;
  }
}
@media (max-width: 1500px) {
	.menu_block_item_span{
    padding: 0 20px!important;
  }
}
@media (max-width: 1400px) {
  .menu_block_item_span{
    padding: 0 13px!important;
    padding-bottom: 15px!important;
  }
}
</style>

<style>
  .el-popover {
      box-shadow: none!important;
  }
</style>