<script setup lang='ts'>
import { getFileUrl } from '~/composables/fileUrl';

interface NavMenuItem {
  id: string,
  type: 'title' | 'smallImage' | 'bigImage' | 'imageOnly' | 'html',
  title: string,
  navigationLink?: string,
  linkImage?: { id: string, storageKey: string }[],
  html?: string,
  children?: NavMenuItem[]
}

  const props = defineProps({
    content: Object as PropType<NavMenuItem>
  })
  console.log("接收参数：", props.content)
  const content: any = props.content
  
  const emit = defineEmits(['openNewPage'])
  const openNewPage = (path: string) => {
    emit("openNewPage", path)
  }
</script>

<template>
  <div>
          <!-- <div v-if="content.showTitle === true" class="title_wrap">{{ content.title }}</div> -->
          <ul class="menu_li_sub_wrap">
            <template v-if="Array.isArray(content.children)">
              <template v-if="content.children.length === 1">
                <li v-if="content.children[0].linkImage && content.children[0].linkImage.length">
                  <img style="max-width: 100%; height: auto;" :src="getFileUrl(content.children[0].linkImage[0].storageKey)" />
                </li>
                <li>
                  <img class="block big_img" :src="content.children[0].img" />
                  <div class="sub_item_title" @click="openNewPage(content.navigationLink)">{{ content.children[0].title }}</div>
                </li>
              </template>
              <template v-else>
                <li v-for="(subItem, subIdx) in content?.children || []" class="list_gap" :key="subIdx + 'sub'">
                  <template v-if="subItem?.type === 'smallImage'">
                    <div class="flex ai_ct item-small-image">
                      <img v-for="image in subItem?.linkImage || []" :key="image.id" :src="getFileUrl(image)" />
                      <!-- <img class="small_img block mr_10" :src="subItem.img" /> -->
                      <span class="sub_item_title" @click="openNewPage(subItem?.navigationLink)">{{ subItem?.title }}</span>
                    </div>
                  </template>
                  <template v-else-if="subItem?.type === 'product'">
                    <div>
                      <img class="block sub_item_product_img" :src="subItem?.img" />
                      <div class="mt_10" @click="openNewPage(subItem?.navigationLink)">{{ subItem?.title }}</div>
                      <!-- <div class="">${{ subItem?.price || '0.00' }}</div> -->
                    </div>
                  </template>
                  <template v-else>
                    <span class="sub_item_title" @click="openNewPage(subItem?.navigationLink)">{{ subItem?.title }}</span>
                  </template>
                </li>
              </template>
            </template>
            <template v-else-if="Array.isArray(content.linkImage)">
              <div class="sub_img_wrap" v-for="img of content.linkImage" :key="img.id" @click="openNewPage(content.navigationLink)">
                <img :src="getFileUrl(img.storageKey)" />
              </div>
            </template>
          </ul>
  </div>
</template>



<style lang="scss" scoped>
  .menu_li_wrap{
    padding: 10px;
  }
  .menu_li_sub_img {
    &-block {
      margin: 0 10px;
    }
  }
  .menu_li_sub_wrap{
    padding: 10px 0;
  }
  .small_img{
    width: 22px;
    height: 22px;
  }
  .big_img{
    max-width: 270px;
    height: fit-content;
  }
  .middle_img{
    width: 136px;
    height: fit-content;
    margin: 0 auto;
  }
  .middle_img_txt{
    text-align: center;
    font-size: 13px;
    color: #000;
  }
  .sub_item_title{
    font-size: 18px;
    font-family: gothic;
    color: #111111;
    // margin-left: 52px;
    user-select: none;
    @media (max-width: 600px) {
      font-size: 12px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .list_gap{
    padding: 5px 0;
  }
  .title_wrap{
    border: none;
    border-bottom: 1px solid #0C1636;
    font-weight: bold;
    padding: 5px 0;
    font-size: 13px;
  }
  .sub_item_product_img{
    width: 225px;
    height: fit-content;
    color: #000;
    font-size: 13px;
    min-height: 20px;
  }

  .item-small-image {
    img {
      width: fit-content;
      max-width: 24px;
      // width: 24px;
      height: auto;
      margin-right: 16px;
      // object-position: center;
      // object-fit: cover;
    }
  }

  .sub_img_wrap {
    width: 100px;
    // height: 100px;
    img {
      width: fit-content;
      max-width: 100%;
      height: auto;
    }

    & + & {
      margin-top: 20px;
    }
  }
</style>
