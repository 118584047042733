<script setup lang="ts">
import {
  ArrowRight,
  ArrowLeft,
  ArrowUpBold,
  CloseBold,
  ArrowDown,
  View,
  Right,
} from "@element-plus/icons-vue";
import { useMainStore } from "@/store/";
import { storeToRefs } from "pinia";
import { Search } from "@element-plus/icons-vue";

// 底部显示的 COPY RIGHT 年信息
const copyRightYear = new Date().getFullYear();

const mainStore = useMainStore();
const setOverflowHeight = ref(0);
const { collectTotal, collectList } = storeToRefs(mainStore);
const currencyNow = computed(() => {
  return mainStore.currencyNow;
});
const router = useRouter();
const route = useRoute();
const nowRoute = ref();
watch(
  () => route.path,
  (newValue: any) => {
    nowRoute.value = newValue;
  },
  { immediate: true }
);
watchLoginAndBack(nowRoute);


const { advertisementApi, userApi, collectApi, shoppingCartApi, orderApi } =
  useApi();

let consumer_id = ref(useCookie("conId").value);
// 搜索框输入内容
const searchText = ref("");
const footer_brand_img = ref([
  { url: "", img: "/images/layout-footer-logo/13-VisaLogo_x32.png" },
  { url: "", img: "/images/layout-footer-logo/9-Mastercard-Emblem_x32.png" },
  { url: "", img: "/images/layout-footer-logo/2-American-Express_x32.png" },
  { url: "", img: "/images/layout-footer-logo/7-Discover-logo_x32.png" },
  {
    url: "",
    img: "/images/layout-footer-logo/6-Diners-Club-International_x32.png",
  },
  { url: "", img: "/images/layout-footer-logo/8-Google_Pay_x32.png" },
  { url: "", img: "/images/layout-footer-logo/1-Apple_Pay_x32.png" },
  { url: "", img: "/images/layout-footer-logo/10-metapaylogo_x32.png" },
  { url: "", img: "/images/layout-footer-logo/11-PayPal_Logo_x32.png" },
  // { url: "", img: "/images/layout-footer-logo/12-shop_pay_x32.png" },
  {
    url: "",
    img: "/images/layout-footer-logo/4-AP_logo_badge_6328x2204_blackmint_png_x32.png",
  },
  { url: "", img: "/images/layout-footer-logo/5-BuyNowPayLater_x32.png" },
]);

const menus = ref();
// 底部菜单手风情选择
const activeFooterMenu = ref();
const contactUs = ref([
  {
    title: "Text/iMessage: +1 (213)-397-4916",
    interlinkage: "tel:+12133974916",
  },
  {
    title: "Email: service@msblue.com",
    interlinkage: "mailto:service@msblue.com",
  },
]);

// 退出登录
const userLogout = () => {
  userDrawer.value = false;
  userApi.outLogin().then((res) => {
    window.localStorage.removeItem("shoppingCartParam");
    window.localStorage.removeItem("shoppingCartGoodsList");
    useCookie("access_token").value = "";
    useCookie("conId").value = "";
    useCookie("_PICU_").value = '';
    consumer_id.value = "";
    router.replace({ path: "/" }).then(() => {
      mainStore.restCollect();
      nextTick(() => {
        window.location.reload();
      });
    });
  });
};

// 移动端搜索的显示
const mobileSearch = ref(false);

// 个人信息列表
const userMenuList = ref([
  {
    name: "My Account",
    url: "instrument",
  },
  {
    name: "My Orders",
    url: "order",
  },
  {
    name: "VIP Rewards",
    url: "integral",
  },
  {
    name: "My Reviews",
    url: "comment",
  },
  {
    name: "My Profile",
    url: "info",
  },
  {
    name: "My Address Book",
    url: "address",
  },
  {
    name: "Email Preferences",
    url: "email",
  },
  {
    name: "Notifications",
    url: "notice",
  }
  // ,
  // {
  //   name: "Exchanges & Returns",
  //   url: "after_sales",
  // },
]);
const userDrawer = ref(false);
const cartDrawer = ref(false);
function showCartDrawer() {
  if (route.path.startsWith('/cart')) {
    // 在购物车页了，不要再显示边框造成同步复杂性
    // 滚动到开始位置
    window.document.documentElement.scrollTo(0, 0);
  } else {
    cartDrawer.value = true;
  }
}
const collectDrawer = ref(false);
// 跳转个人页面
const toUserPage = (url: number) => {
  userDrawer.value = false;
  router.push(`/user/${url}`);
};

// 个人信息
const userInfo = ref({});

// 数据字典的值
// const currencyRate = useState('currencyRate') || reactive([])
const currencyRate = ref([]);
// console.log(currencyRate)
const counter = useState("loginVisible", () => ref(false));
const loginVisible = useState("loginVisible");
const closeLogin = () => {
  loginVisible.value = false;
};
// 当前货币
// let currencyNow = ref({})
// let currencyNow = useState('currencyNow') || ref({})
// let currencyNowDel = ref([])
// 改变货币选择
const handleCurrency = (e: any) => {
  // currencyNowDel.value = e
  // currencyNow.value = e
  mainStore.changeCurrencyNow(e);
  // mainStore.currencyNow = e
  // refreshNuxtData()
};
// 搜索框搜索方法
const handleSearch = (str: string) => {
  if (!str.trim()) {
    ElMessage.error("Please enter a search keyword!");
    return;
  }

  if (route.path === "/searchResult") {
    router.replace({
      path: "/searchResult",
      query: {
        search: str,
      },
    });
  } else {
    router.push({
      path: "/searchResult",
      query: {
        search: str,
      },
    });
  }
};
// const tokenStr = useCookie('access_token').value
// const { value } = useCookie('access_token')

const currentUser = computed(() => {
  return mainStore.currentUser;
});

const isFoldMbHeader = ref(false);

const foldMbHeader = computed(() => {
  if (
    (route.path === "/depthCustomization" ||
      route.path.includes("/products") ||
      route.path === "/engagementRing") &&
    isFoldMbHeader.value
  ) {
    return true;
  }

  return false;
});

function noToken() {
  return (
    useCookie("access_token").value == "undefined" ||
    useCookie("access_token").value == undefined ||
    useCookie("access_token").value == ""
  );
}
const NoLoginJumps = ['/', '/wishlist'];
const jump = (url: any) => {
  nextTick(() => {
    if (!currentUser.value && !NoLoginJumps.includes(url)) {
      loginVisible.value = !loginVisible.value;
      return;
    }
    router.push(url);
  });
};

const toPage = (url: any) => {
  console.log("url:", url);
  if (url) {
    // router.push(url)
    window.location.href = url;
  }
};

// 页面顶部的广告
const topBanner = ref();

// 左侧菜单底部数据展开
const activeBottomName = ref();
// 底部数据 --- 关于我们
const bottomMenuGYWM = ref([]);
// 底部数据 --- 品牌教育
const bottomMenuPPJY = ref([]);
// 底部数据 --- 客户服务
const bottomMenuKHFW = ref([]);
// 底部数据 --- 加入我们
const bottomMenuFollow = ref([]);
// 登录弹框广告图和文字
const loginAdList = ref([]);

import SVG_FACEBOOK from '/assets/social/facebook.svg?raw';
import SVG_TIKTOK from '/assets/social/tiktok.svg?raw';
import SVG_INS from '/assets/social/ins.svg?raw';
import SVG_PINTEREST from '/assets/social/pinterest.svg?raw';
import SVG_YOUTUBE from '/assets/social/youtube.svg?raw';

// 替换 SVG 图标
function replaceFollowSvgSrc(items: any[]) {
  items.forEach(item => {
    if (item.interlinkage?.includes('tiktok')) {
      item.advertisementImage = [{ raw: SVG_TIKTOK }];
    }
    else if (item.interlinkage?.includes('facebook')) {
      item.advertisementImage = [{ raw: SVG_FACEBOOK }];
    }
    else if (item.interlinkage?.includes('instagram')) {
      item.advertisementImage = [{ raw: SVG_INS }];
    }
    else if (item.interlinkage?.includes('pinterest')) {
      item.advertisementImage = [{ raw: SVG_PINTEREST }];
    }
    else if (item.interlinkage?.includes('youtube')) {
      item.advertisementImage = [{ raw: SVG_YOUTUBE }];
    }
  });
}

// 处理
const handleAdvertise = () => {
  // 获取广告，给莫桑石
  return advertisementApi
    .getAdvertisement({
      advertisementTypeString:
        "top_mss,top_banner,home_bottom_gywm,home_bottom_ppjy,home_bottom_khfw,foot_followus,login_ad",
    })
    .then(async (res) => {
      // console.log("获取所有广告：", res)
      // 处理莫桑石的
      const mss = res.data.filter((e: any) => {
        return e.advertisementType === "top_mss";
      });
      const arr: any = [];
      mss.forEach((element: any) => {
        const json = {
          showTitle: true,
          type: "image",
          typeName: element.title,
          toPage: element.interlinkage,
          sort: element.sort,
          img: fileUrl + element?.advertisementImage[0]?.storageKey,
        };
        arr.push(json);
      });

      // 顶部广告 top_banner
      const top = res.data.filter((e: any) => {
        return e.advertisementType === "top_banner";
      });
      await sortList(top);
      topBanner.value = top;
      // 处理底部数据 --- 关于我们
      const gywm = res.data.filter((e: any) => {
        return e.advertisementType === "home_bottom_gywm";
      });
      await sortList(gywm);
      bottomMenuGYWM.value = gywm;
      // console.log("关于我们：", gywm)
      // 处理底部数据 --- 品牌教育
      const ppjy = res.data.filter((e: any) => {
        return e.advertisementType === "home_bottom_ppjy";
      });
      await sortList(ppjy);
      bottomMenuPPJY.value = ppjy;
      // console.log("品牌教育", ppjy)
      // 处理底部数据 --- 客户服务
      const khfw = res.data.filter((e: any) => {
        return e.advertisementType === "home_bottom_khfw";
      });
      await sortList(khfw);
      bottomMenuKHFW.value = khfw;
      // console.log("客户服务", khfw)

      const follow: [] = res.data.filter((e: any) => {
        return e.advertisementType === "foot_followus";
      });
      await sortList(follow);
      bottomMenuFollow.value = follow;
      replaceFollowSvgSrc(bottomMenuFollow.value);
      console.log("加入我们：", bottomMenuFollow.value);

      const loginAd: [] = res.data.filter((e: any) => {
        return e.advertisementType === "login_ad";
      });
      await sortList(loginAd);
      loginAdList.value = loginAd;
    });
};

// 左侧菜单内容获取
const getNavi = () => {
  return userApi.getNavigation().then((res) => {
    menus.value = res.data;
  });
};

// 左侧菜单划出
const openLeftMenu = ref(false);
// 二级菜单内容
const leftMenuSecond = ref({});
// 二级菜单是否显示
const showMenuSecond = ref(false);

// 打开左侧菜单
const openMenu = () => {
  openLeftMenu.value = true;
};
// 跳转页面且关闭
const openNewPage = (path: string) => {
  toNewPage(path);
  openLeftMenu.value = false;
};
// 二级菜单展开的
const activeSecondName = ref(0);
// 打开二级菜单
const openSecond = (item: any, open: boolean) => {
  showMenuSecond.value = open;
  activeSecondName.value = 0;
  if (open) {
    leftMenuSecond.value = item;
  }
};
// 关闭左侧菜单
const closeLeftMenu = () => {
  openSecond({}, false);
};
// 跳转商品列表，时间倒序、销量倒序
const toProductList = (type: number) => {
  router.push("/productList?sort=" + type);
};

// 用户未读数量
const unreadNumber = ref();
// 获取收藏列表 -- 获取时间顺序top20
const getCollectList = () => {
  return mainStore.getCollectList();
};
// 删除收藏
const delCollect = (goodsId: any, index: number) => {
  if(currentUser.value){
    collectApi
      .addCollect({ consumerId: consumer_id.value, goodsId: goodsId })
      .then((res) => {
        ElMessage.success(res.data);
      });
  }else{
    const collectLists = JSON.parse(localStorage.getItem('collectLists'))
    let param = []
    param = collectLists.filter((item,i) => {return i != index}) 
    if(param.length > 0){
      param.commodityName = param?.commodity_name  
      param.referencePrice = param?.reference_price
      param.minPrice = param?.min_price
      localStorage.setItem('collectLists',JSON.stringify(param))
      const mainStore = useMainStore()
			mainStore.getCollectList()
      getCollectList()
    }else{
      localStorage.removeItem('collectLists')
      const mainStore = useMainStore()
			mainStore.getCollectList()
      getCollectList()

    }
  }
  
};
// 批量删除收藏
const delAllCollect = () => {
  if(currentUser.value){
    const arr = collectList.value.map((e: any) => {
      return e.id;
    });
    collectApi.delCollectList({ ids: arr }).then((res) => {
      if (res.success) {
        ElMessage.success("Delete Succeeded");
      }
    });
  }else{
    localStorage.removeItem('collectLists')
    const mainStore = useMainStore()
			mainStore.getCollectList()
      getCollectList()
  }
  
};

// 货币排序
const sortCurrency = async (arr: any) => {
  arr.sort(function (a: any, b: any) {
    //callback
    if (!a.id) {
      return 1;
    }
    if (!b.id) {
      return -1;
    }
    if (a.id > b.id) {
      // a b 第一个第二个
      return 1; //返回正数 ，b排列在a之前
    } else {
      return -1; //返回负数 ，a排列在b之前
    }
  });
};

// 购物车列表
const { shoppingList, shoppingData, shoppingTotal } = storeToRefs(mainStore);
// let shoppingData = ref({
// 	goodsMoney: 0,
// 	goodsDiscount: 0,
// 	goodsSubtotal: 0
// })
// 删除购物车项
console.log(shoppingList);
const delLoading = ref(false);
const delShopping = (good: any, index: number) => {
  if (mainStore.currentUser) {
    if (delLoading.value === true) {
      return ElMessage.warn("Please waiting!");
    }
    delLoading.value = true;
    shoppingCartApi
      .delGoods(good.id)
      .then((res) => {
        if (res.success) {
          if (good?.accessory_cart_id && !good?.accessory_ring_id) {
            shoppingCartApi.delGoods(good?.accessory_cart_id).then((res) => {
              if (res.success) {
                mainStore.getShoppingList();
              }
            });
          } else {
            mainStore.getShoppingList();
          }
        }
        // shoppingList.value.splice(index, 1)
        console.log(shoppingList);
      })
      .finally(() => {
        delLoading.value = false;
      });
  } else {
    if (Array.isArray(shoppingList.value)) {
      console.error(shoppingList.value);

      const deleteIndexs = [index];

      // 判断主副戒
      if (good?.ringKey) {
        // 删除副戒
        const findIndex = shoppingList.value.findIndex(
          (item, index) => item?.associateRingKey == good?.ringKey
        );

        if (findIndex) {
          deleteIndexs.push(findIndex);
        }
      }
      const items = shoppingList.value?.filter(
        (item: any, i) => !deleteIndexs.includes(i)
      );
      shoppingList.value = [...items];
      localStorage.setItem("shoppingCartLocalItems", JSON.stringify(items));
      mainStore.getShoppingList();
    }
  }
};
// 批量删除购物车项
const delAllGoods = () => {
  if (consumer_id.value) {
    const arr = shoppingList.value.map((e: any) => {
      return e.id;
    });
    shoppingCartApi.delGoodsList({ ids: arr }).then((res) => {
      console.log("批量删除购物车：", res);
      console.log(shoppingList);
      if (res.success) {
        ElMessage.success("Delete Succeeded");
        getShoppingCart();
        mainStore.getShoppingList();
      }
    });
  } else {
    getShoppingCartFromLocal();
    localStorage.removeItem("shoppingCartLocalItems");
  }
};

// 获取购物车列表
const getShoppingCart = () => {
  return mainStore.getShoppingList();
  // return shoppingCartApi.getShoppingCart({current: 1,pageSize: 100}).then((res:any)=>{
  //     console.log("购物列表：", res)
  //     shoppingList.value = res.data?.pageMap?.records
  //     shoppingData.value.goodsMoney = res.data.goodsMoney
  //     shoppingData.value.goodsDiscount = res.data.goodsDiscount
  //     shoppingData.value.goodsSubtotal = res.data.goodsSubtotal
  // })
};

// 从本地获取购物车
const getShoppingCartFromLocal = () => {
  const storageData = localStorage.getItem("shoppingCartLocalItems");
  let items = [];
  if (storageData) {
    items = JSON.parse(storageData);
    //  console.log(items)
    const totalGoodsMoney = items
      .filter((i: any) => i)
      .reduce((total, item) => total + item?.goodsInformation?.goodsPrice, 0);
    shoppingData.value.goodsMoney = totalGoodsMoney;
    shoppingData.value.goodsDiscount = 0;
    shoppingData.value.goodsSubtotal = totalGoodsMoney;
    // mainStore.shoppingData = {
    //   goodsMoney: totalGoodsMoney,
    //   goodsDiscount: 0,
    //   goodsSubtotal: totalGoodsMoney
    // }
  }
  // shoppingList.value = items;
  mainStore.shoppingList = items;
  mainStore.shoppingTotal = items.reduce(
    (total: any, obj: any) => total + obj?.quantity,
    0
  );
};

// 结算按钮，跳转到下单页
const goCreateOrder = () => {
  let jmShoppingCartVoList = shoppingList.value.map((item: any, index, arr) => {
    let obj = {
      id: item.id,
      fontId: item.fontId,
      goodsId: item.goodsId,
      graveFont: item.graveFont,
      specificationId: item.specificationId,
      attributeId: item.attributeId,
      quantity: item.quantity,
      otherAttributes: item.otherAttributes,
    };
    if (item.giftList && item.giftList.length) {
      item.giftList.forEach((t: any) => {
        if (t.isChange) {
          obj.giftId = t.id;
        }
      });
    }
    if (item.protectionList && item.protectionList.length) {
      item.protectionList.forEach((t: any) => {
        if (t.isChange) {
          obj.protectionId = t.id;
        }
      });
    }
    return obj;
  });

  let param = {
    jmShoppingCartVoList,
    totalMoney: shoppingData.value.goodsSubtotal || 0,
    giveGoodsList: [],
    score: 0,
    discountMoney: shoppingData.value.goodsDiscount || 0,
  };
  orderApi.createOrder(param, {}).then((res) => {
    checkOutLoading.value = false
    if (res.success) {
      cartDrawer.value = false
      // 清除本地缓存商品数据
      // localStorage.setItem("shoppingCartLocalItems", []);
      // mainStore.getShoppingList();

      router.push({ path: "/checkout/" + res.data.goodsOrderId });
    }
  });
};

// 滚到加载事件 修改顶部样式
const load = async () => {
  let scrollTop = document.documentElement.scrollTop;
  // console.log("滚动了的高度：", scrollTop)
  // 获取各个class
  // var searchRight = document.getElementsByClassName("search_right");
  // 悬浮的时候货币切换不要了0725设计图上没有。
  // var headerMoneyRight = document.getElementsByClassName("header_money_right");

  var siteHeader = document.getElementById("siteHeader");
  // 移动端左侧菜单的顶部空余
  var overlay = document.querySelector(".el-overlay.left-menu-overlay");
  // 滚动500px后改变
  if (scrollTop >= 50) {
    // 超出，变小
    if (!siteHeader?.classList.contains("sm-site-header")) {
      siteHeader.classList.add("sm-site-header");
    }

    // UI不要这两部分内容了
    // for (var i = 0; i< searchRight.length; i++) {
    // 	searchRight[i].style.display = 'block'
    // }
    // for (var i = 0; i< headerMoneyRight.length; i++) {
    // 	headerMoneyRight[i].style.display = 'block'
    // }

    // overlay.style.top = '80px'
    if (overlay) {
      overlay.style.top = "88px";
    }
  } else {
    // 未超出
    if (siteHeader?.classList.contains("sm-site-header")) {
      siteHeader.classList.remove("sm-site-header");
    }

    // UI不要这两部分内容了
    // for (var i = 0; i< searchRight.length; i++) {
    // 	searchRight[i].style.display = 'none'
    // }
    // for (var i = 0; i< headerMoneyRight.length; i++) {
    // 	headerMoneyRight[i].style.display = 'none'
    // }

    // overlay.style.top = '128px'
    if (overlay) {
      overlay.style.top = "108px";
    }
  }

  isFoldMbHeader.value = scrollTop >= 50;
};

// 获取原价
const getOriginalPrice = (item: any) => {
  return item?.referencePrice || 0;
  // if (item.spcsList && item.spcsList.length && item.spcsList[0]?.specInfo)
  // {
  //   return item.spcsList[0]?.originalPrice || 0
  // } else {
  //   return item?.referencePrice || 0
  // }
};

// 获取现价
const getSalePrice = (item: any, index: any) => {
  if (item?.minPrice) {
    return item.minPrice;
  }
  return 0;
  // if (item.miniSpecs && item.miniSpecs.length) {
  //   return item.miniSpecs[0]?.price || 0
  // } else {
  //   return 0
  // }
};

function getWinHeight() {
  const h = window.innerHeight;
  if (h < 1000) {
    setOverflowHeight.value = h - 80 - 78;
  } else {
    setOverflowHeight.value = 0;
  }
}

const emailAddress = ref("");
const registrationDialog = reactive({
  open: false,
  errorMsg: "",
  type: 0,
});

const canRegisterEmail = ref(true);
const validateEmail = (email) => {
  let reg =
    /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  let flag = reg.test(email);
  return flag;
};

const errorFunc = (message) => {
  ElMessage({
    message,
    type: "error",
  });
};
const countdown = ref(120);
const setTime = (val) => {
  let timer = null;
  timer = setInterval(function () {
    countdown.value--;
    if (countdown.value == 0) {
      canRegisterEmail.value = true;
      clearInterval(timer);
    }
  }, 1000);
};

const bottomUserRegistratioLoading = ref(false);
// 底部邮箱注册
const bottomUserRegistration = () => {
  if (!emailAddress.value || !validateEmail(emailAddress.value)) {
    errorFunc("Please enter the correct mailbox format！");
    return;
  }
  if (!canRegisterEmail.value) {
    errorFunc("Retry after 120 seconds");
    return;
  }

  bottomUserRegistratioLoading.value = true;

  userApi
    .defaultRegistration({
      account: emailAddress.value,
    })
    .finally(() => {
      bottomUserRegistratioLoading.value = false;
    })
    .then(() => {
      canRegisterEmail.value = false;
      registrationDialog.type = 1;
      registrationDialog.open = true;
      setTime();
    })
    .catch((err) => {
      ElMessage.error(err.errorMessage)
      // registrationDialog.type = 0;
      // registrationDialog.errorMsg = err.errorMessage;
      // registrationDialog.open = true;
    });
};

function copyText(text: string) {
  // 创建一个隐藏的 textarea 元素
  const textarea = document.createElement("textarea");
  textarea.value = text;
  textarea.style.position = "fixed";
  textarea.style.left = "-9999px";
  textarea.style.top = "-9999px";

  // 将 textarea 添加到页面中
  document.body.appendChild(textarea);

  // 选中并复制文本
  textarea.select();
  document.execCommand("copy");

  ElMessage.success("The copy succeeded");
  // 移除 textarea 元素
  document.body.removeChild(textarea);
}

onMounted(async () => {
  await mainStore.getCurrentUser();
  await mainStore.getCollectList();
  console.warn("default Mounted", mainStore.currentUser);
  getWinHeight();
  window.addEventListener("resize", getWinHeight, false);
  // 数据字典取“订婚”的数据
  // const dicts = await getDictList()
  // 获取货币
  userApi.getCurrency({}).then((res: any) => {
    sortCurrency(res.data);
    console.log("货币：", res.data);
    // useState('currencyRate', () => (res.data))
    if (res.data) currencyRate.value = res.data;
    //  currencyNowDel.value = res.data[0]??[]
    // currencyNow.value = res.data[0]??{}
    if (res.data?.length) {
      let localcurrencyNow = JSON.parse(
        localStorage.getItem("muslb-currency-now")
      );
      mainStore.changeCurrencyNow(localcurrencyNow || res.data[0] || {});
      //   mainStore.currencyNow = res.data[0] || {}
    }
  });

  getNavi();
  // 处理广告数据
  handleAdvertise();
  getShoppingCart();
  if (currentUser.value) {
    userApi.getUserInfo(consumer_id.value + "").then((res: any) => {
      if (res.success) {
        console.log(res);
        userInfo.value = res.data;
      }
    });
    getCollectList();
    // 获取未读消息数量
    // /front/customized/unread
    userApi.getUnread().then((res: any) => {
      console.log("返回数量：", res);
      unreadNumber.value = res.data;
    });
  }

  // else {
  //   getShoppingCartFromLocal();
  // }

  window.addEventListener("scroll", load);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", load);
  window.removeEventListener("resize", getWinHeight);
});

// const getLocalStorage = () =>{
// 	let localcurrencyNow = JSON.parse(localStorage.getItem('muslb-currency-now'));
// 	mainStore.changeCurrencyNow(localcurrencyNow || {})
// }

// const sizeMode = inject('sizeMode', 'md');

// import("~/assets/styles/default.scss")
// const { sizeMode } = useSizeMode()
function onViewCard() {
  cartDrawer.value = false;
  cartDrawer.value = false;
  console.debug("onViewCard");
  router.push("/cart");
}
const checkOutLoading = ref(false)
// 购物车一键下单
function onCheckOut(){
  if(checkOutLoading.value){
    return
  }
  checkOutLoading.value = true
  if(mainStore.currentUser){
    // 登录状态下单
    orderApi.shoppingCartOrder().then(res=>{
      checkOutLoading.value = false
      if(res.success){
        checkOutLoading.value = false
        cartDrawer.value = false
        // 更新购物车
        getShoppingCart()
        router.push({ path: `/checkout/${res.data?.goodsOrderId}` });
      }
    }).catch(err=>{
      checkOutLoading.value = false
    })
  }else{
    // 未登录下单
    goCreateOrder()
  }
  
}
function getQuatity(item: any) {
  if (item?.quantity > 1) {
    return " x" + item?.quantity;
  }
  return "";
}
function getGoodsPrice(item:any){
	if(item?.goodsInformation?.goodsPrice){
		return item?.goodsInformation?.goodsPrice * item?.quantity
	}else if(item?.goods_price){
		return item?.goods_price * item?.quantity * item?.quantity
	}{
		return 0
	}
                                            
}
function fixShoppingItemPropNames(item: any) {
  if (item.consumer_id) {
    item.consumerId = item.consumer_id;
  }
  if (item.goods_code) {
    item.goodsCode = item.goods_code;
  }
  if (item.goods_id) {
    item.goodsId = item.goods_id;
  }
  if (item.goods_images) {
    item.goodsImages = JSON.parse(item.goods_images);
  }
  if (item.goods_price) {
    item.goodsPrice = item.goods_price;
  }
  if (item.specification_id) {
    item.specificationId = item.specification_id;
  }
  if (item.grave_font) {
    item.graveFont = item.grave_font;
  }
  if (item.attribute_id) {
    item.attributeId = item.attribute_id;
  }
}

async function onShoppingQuantityChange(item: any, quantity: number) {
  console.debug('onShoppingQuantityChange', quantity);
  if (mainStore.currentUser) {
    const updateItem = { ...item, quantity };
    fixShoppingItemPropNames(updateItem);
    await shoppingCartApi.shoppingCartUpdate(updateItem);
    item.quantity = quantity;
    mainStore.getShoppingList();
  } else {    
    const storageData = localStorage.getItem("shoppingCartLocalItems");
    if (!storageData) {
      return;
    }
    const items = JSON.parse(storageData);
    const it = items.findIndex(i => i.id == item.id);
    if (it > -1) {
      item.quantity = quantity;
      if (item.quantity <= 0) {
        return;
      }
      items[it] = item;
    } else {
      console.warn("offline cart item not found");
    }
    localStorage.setItem("shoppingCartLocalItems", JSON.stringify(items));
    mainStore.getShoppingList();
  }
}

const onClickMe = () => {
  if (!currentUser.value) {
    loginVisible.value = !loginVisible.value;
    return;
  }
  toNewPage("/user/instrument");
};
const onUserCenter = () => {
  if (!currentUser.value) {
    loginVisible.value = !loginVisible.value;
    return;
  }
  userDrawer.value = true;
};

const sizeMode = inject("sizeMode", "xxl");
</script>
<template>
  <div>
    <div v-if="['xs'].includes(sizeMode)" class="layout">
      <el-affix class="layout-top" target="#__nuxt" :offset="0" :z-index="112">
        <section class="layout-header" id="siteHeader">
          <div
            :style="foldMbHeader ? 'height: 0;padding: 0;' : ''"
            class="layout-header__nav"
          >
            <span
              v-if="topBanner && topBanner.length"
              @click="toNewPage(topBanner[0].interlinkage)"
            >
              <span v-for="(item, index) in topBanner" :key="index">
                {{ item.title }}
              </span>
              <span>>></span>
            </span>
          </div>

          <div class="layout-header__inner">
            <div class="layout-header__inner__top">
              <div
                class="layout-header__inner__icon layout-header__inner__menu"
                @click="openMenu"
              >
                <img src="~/assets/menu_icon.svg" class="menu_icon" />
              </div>

              <div
                class="layout-header__inner__icon layout-header__inner__logo"
              >
                
                <div @click="jump('/'),openLeftMenu = false">
                  <img
                    style="display: block"
                    srcset="
                      /images/topLogo.png     750w,
                      /images/topLogo@2x.png 1200w
                    "
                    src="/images/topLogo@2x.png"
                  />
                </div>
              </div>

              <div class="layout-header__inner__right">
                <div
                  class="layout-header__inner__right__badge"
                  @click="jump('/user/instrument')"
                >
                  <el-badge
                    :value="unreadNumber"
                    :hidden="!unreadNumber"
                    :max="99"
                    style="display: block"
                  >
                    <img
                      style="display: block; width: 0.25rem"
                      src="~/assets/m_loading.png"
                    />
                  </el-badge>
                </div>

                <div
                  class="layout-header__inner__right__badge"
                  @click="jump('/wishlist')"
                >
                  <el-badge
                    :value="collectTotal"
                    :hidden="!collectTotal"
                    :max="99"
                    style="display: block；"
                  >
                    <img
                      style="display: block; width: 0.3rem"
                      src="~/assets/m_collect.png"
                    />
                  </el-badge>
                </div>

                <div
                  class="layout-header__inner__right__badge"
                  @click="router.push('/cart')"
                >
                  <el-badge
                    :value="shoppingTotal"
                    :hidden="!shoppingTotal"
                    :max="99"
                    style="display: block;"
                  >
                    <!-- <img src="~/assets/shop_car_icon.svg" /> -->
                    <img
                      style="display: block; width: 0.25rem"
                      src="~/assets/m_cart_icon.png"
                    />
                  </el-badge>
                </div>
              </div>
            </div>

            <div
              :style="foldMbHeader ? 'height: 0;margin-top: 0;' : ''"
              class="layout-header__inner__search"
            >
              <el-input
                v-model="searchText"
                type="search"
                placeholder="Search entire store here"
                @change="handleSearch"
              >
                <template #suffix>
                  <div
                    class="layout-header__inner__search__icon"
                    @click="handleSearch(searchText)"
                  ></div>
                </template>
              </el-input>
            </div>
          </div>
        </section>
      </el-affix>

      <div class="layout-content">
        <slot />
        <!-- 左侧菜单内容 -->
        <client-only>
          <el-drawer
            class="left-menu-container"
            v-model="openLeftMenu"
            @close="closeLeftMenu"
            direction="ltr"
            :show-close="false"
            :with-header="false"
            :modal-class="
              foldMbHeader ? 'layout-slide layout-slide--fold' : 'layout-slide'
            "
            size="80%"
            lock-scroll
          >
            <template #default>
              <div>
                <!-- 一级菜单样式 -->
                <div>
                  <div
                    class="left-menu-top"
                    @click="
                      () => {
                        !consumer_id && (loginVisible = true);
                      }
                    "
                  >
                    <UserTiers :userInfo="userInfo" size="mobile" roundedEdges />
                    <template v-if="consumer_id">
                      <!-- <NuxtLink to="/user/instrument" style="text-decoration: none"> -->
                      <div
                        class="left-menu-top-text"
                        @click="router.push({ path: '/user/instrument' }),openLeftMenu=false"
                      >
                        <div class="left-menu-top-text-name">
                          {{ userInfo.name }}
                        </div>
                        <div class="left-menu-top-text-money">
                          {{ userInfo.score }} GEMS =
                          {{ filterCurrencyUSD(userInfo?.USD || 0) }}
                        </div>
                      </div>
                      <el-icon style="font-size: 0.23rem"
                        ><ArrowRight
                      /></el-icon>
                      <!-- </NuxtLink> -->
                    </template>
                    <div v-else class="left-menu-top-text">SIGN IN</div>
                  </div>
                  <!-- 第一部分 -->
                  <div class="left-menu-list">
                    <div
                      class="left-menu-list-line"
                      @click="openNewPage('/collections/all?sort=hot')"
                    >
                      HOT
                      <!-- 每日上新 -->
                    </div>
                    <div
                      class="left-menu-list-line"
                      @click="openNewPage('/collections/all?sort=new')"
                    >
                      NEW
                      <!-- 热门畅销 -->
                    </div>
                  </div>
                  <el-divider />
                  <!-- 第二部分 -->
                  <div class="left-menu-list">
                    <div
                      v-for="(item, index) in menus"
                      :key="index"
                      class="left-menu-list-line"
                      @click="openSecond(item, true)"
                    >
                      <div>
                        {{ item.title }}
                      </div>
                      <el-icon><ArrowRight /></el-icon>
                    </div>
                  </div>
                  <el-divider />
                  <!-- 第三部分 -->
                  <div class="left-menu-list-activeBottomName">
                    <el-collapse v-model="activeBottomName" accordion>
                      <el-collapse-item title="COUNTRY/REGIOIN" :name="0">
                        <div
                          class="left-menu-list-activeBottomName-line"
                          :class="[currencyNow === item && 'is-light']"
                          v-for="(item, index) in currencyRate"
                          :key="index"
                          @click="handleCurrency(item), (openLeftMenu = false)"
                        >
                          <div>
                            <el-image
                              style="margin-right: 8px"
                              :src="
                                item?.picture && item?.picture.length
                                  ? fileUrl + item.picture[0].storageKey
                                  : ''
                              "
                              fit="cover"
                            />
                            <div style="font-size: 13px;">{{ item.currency }}</div>
                          </div>
                        </div>
                      </el-collapse-item>
                      <el-collapse-item title="ABOUT MSBLUE" :name="1">
                        <div
                          class="left-menu-list-line"
                          v-for="(item, index) in bottomMenuGYWM"
                          :key="index"
                          @click="toPage(item?.interlinkage)"
                        >
                          {{ item.title }}
                        </div>
                      </el-collapse-item>
                      <el-collapse-item title="EDUCATION" :name="2">
                        <div
                          class="left-menu-list-line"
                          v-for="(item, index) in bottomMenuPPJY"
                          :key="index"
                          @click="toPage(item?.interlinkage)"
                        >
                          {{ item.title }}
                        </div>
                      </el-collapse-item>
                      <el-collapse-item title="CUSTOMER CARE" :name="3">
                        <div
                          class="left-menu-list-line"
                          v-for="(item, index) in bottomMenuKHFW"
                          :key="index"
                          @click="toPage(item?.interlinkage)"
                        >
                          {{ item.title }}
                        </div>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                  <el-divider />
                  <!-- 第四部分 -- 静态文本 -->
                  <div class="left-menu-list left-menu-content">
                    <div class="left-menu-msg">
                      <p v-for="(item, index) in contactUs" :key="index">
                        {{ item.title }}
                      </p>
                    </div>
<!--                    <div class="left-menu-text">-->
<!--                      <p>Monday to Friday</p>-->
<!--                      <p>In the eastern United States time at 9 am to 8 PM</p>-->
<!--                      <p>On Saturday and Sunday</p>-->
<!--                      <p>At 9 a.m. to 5:30 p.m. eastern time</p>-->
<!--                    </div>-->
                    <div class="left-menu-list-btn">CONTACT US</div>
                  </div>
                  <el-divider />
                  <div
                    v-if="consumer_id"
                    class="left-menu-list left-menu-logout"
                    @click="userLogout()"
                  >
                    <div class="left-menu-logout-icon"></div>
                    LOGOUT
                  </div>
                  <div style="padding: 20px"></div>
                </div>
              </div>
              <!-- 二级菜单 -->
              <div>
                <transition name="menu-second">
                  <div v-if="showMenuSecond" class="left-menu-second">
                    <div class="left-menu-second-title">
                      <el-icon @click="openSecond({}, false)"
                        ><ArrowLeft
                      /></el-icon>
                      <span>{{ leftMenuSecond?.title }}</span>
                    </div>
                    <el-collapse
                      v-if="leftMenuSecond?.title !== '莫桑石'"
                      v-model="activeSecondName"
                      accordion
                    >
                      <el-collapse-item
                        v-for="(item, index) in leftMenuSecond.children"
                        :key="index"
                        :name="index"
                      >
                        <template #title>
                          <div class="el-collapse-item-title">
                            {{ item.title }}
                          </div>
                        </template>
                        <menu-left
                          :content="item"
                          @openNewPage="openNewPage"
                        ></menu-left>
                      </el-collapse-item>
                    </el-collapse>
                  </div>
                </transition>
              </div>
            </template>
          </el-drawer>
        </client-only>
      </div>

      <div class="layout-footer">
        <div class="layout-footer__emailwrap">
          <div class="layout-footer__emailwrap__title">
            SIGN UP FOR SPECIAL OFFERS!
          </div>

          <div class="layout-footer__emailwrap__dsc">
            Join our VIP list AND shop today with $25 off your FIRST ORDER
          </div>

          <el-input
            v-model="emailAddress"
            type="search"
            placeholder="Email Address"
            clearable
          >
            <template #append>
              <el-button
                :loading="bottomUserRegistratioLoading"
                @click="bottomUserRegistration"
                class="layout-footer__emailwrap__btn"
              >
                Submit
              </el-button>
            </template>
          </el-input>
        </div>

        <div class="layout-footer__fllowwrap">
          <div class="layout-footer__fllowwrap__title">FOLLOW US ON</div>

          <div class="layout-footer__fllowwrap__imgwrap">
            <div
              v-for="(item, index) in bottomMenuFollow"
              :key="index"
              class="block-click"
              @click="toNewPage(item.interlinkage)"
            >

              <div v-if="item?.advertisementImage?.[0]?.raw" v-html="item.advertisementImage[0].raw"></div>
              <img
                v-else-if="
                  item?.advertisementImage &&
                  item?.advertisementImage.length
                "
                :src="getFileUrl(item?.advertisementImage)"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>

        <div class="layout-footer__menu">
          <el-collapse v-model="activeFooterMenu" accordion>
            <el-collapse-item title="CONTACT US" :name="1">
              <!-- <template #title>
            <span>CONTACT US1</span>
          </template> -->
              <div
                class="layout-footer__menu__line"
                v-for="(item, index) in contactUs"
                :key="index"
                @click="toPage(item?.interlinkage)"
              >
                <img
                  v-if="index === 0"
                  class="layout-footer__menu__icon"
                  src="~/assets/img/icon_text_msg.png"
                />
                <img
                  v-else
                  class="layout-footer__menu__icon"
                  src="~/assets/img/icon_text_email.png"
                />
                <span>{{ item.title }}</span>
              </div>
            </el-collapse-item>

            <el-collapse-item title="ABOUT MSBLUE" :name="2">
              <div
                class="layout-footer__menu__line"
                v-for="(item, index) in bottomMenuGYWM"
                :key="index"
                @click="toPage(item?.interlinkage)"
              >
                {{ item.title }}
              </div>
            </el-collapse-item>

            <el-collapse-item title="EDUCATION" :name="3">
              <div
                class="layout-footer__menu__line"
                v-for="(item, index) in bottomMenuPPJY"
                :key="index"
                @click="toPage(item?.interlinkage)"
              >
                {{ item.title }}
              </div>
            </el-collapse-item>
            <el-collapse-item title="CUSTOMER CARE" :name="4">
              <div
                class="layout-footer__menu__line"
                v-for="(item, index) in bottomMenuKHFW"
                :key="index"
                @click="toPage(item?.interlinkage)"
              >
                {{ item.title }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

        <div class="layout-footer__statement">
          <span>© {{ copyRightYear }} MSBLUE JEWELRY - All Right reserved.</span>
        </div>

        <div class="layout-footer__badges">
          <span
            v-for="(item, idx) in footer_brand_img"
            :key="'brand_icon_wrap' + idx"
            :href="item.url"
          >
            <img :src="item.img" />
          </span>
        </div>
      </div>

      <!-- 底部菜单，移动端 -->
      <el-affix position="bottom" :offset="0" style="width: 100% !important" v-if="!route.path.includes('checkout')">
        <div class="layout-bottom">
          <div class="layout-bottom__item" @click="toNewPage('/')">
            <div
              class="layout-bottom__item__icon logo-icon"
              :style="`background: url('/images/${
                nowRoute === '/' ? 'homeSelect' : 'homeIcon'
              }.png') center center / contain no-repeat`"
            ></div>
            <div class="layout-bottom__item__title">HOME</div>
          </div>
          <div
            class="layout-bottom__item"
            @click="toNewPage('/collections/all')"
          >
            <div
              class="layout-bottom__item__icon"
              :style="`background: url('/images/${
                nowRoute === '/collections/all'
                  ? 'categorySelect'
                  : 'categoryIcon'
              }.png') center center / contain no-repeat`"
            ></div>
            <div class="layout-bottom__item__title">CATEGORY</div>
          </div>
          <!-- <div
            class="layout-bottom__item"
            @click="toNewPage('/productCustomization')"
          >
            <div
              class="layout-bottom__item__icon"
              :style="`background: url('/images/${
                nowRoute === '/productCustomization'
                  ? 'customizedSelect'
                  : 'customizedIcon'
              }.png') center center / contain no-repeat`"
            ></div>
            <div class="layout-bottom__item__title">CUSTOMIZED</div>
          </div> -->
          <div class="layout-bottom__item" @click="toNewPage('/cart')">
            <div
              class="layout-bottom__item__icon"
              :style="`background: url('/images/${
                nowRoute === '/cart' ? 'cartSelect' : 'cartIcon'
              }.png') center center / contain no-repeat`"
            ></div>
            <div class="layout-bottom__item__title">CART</div>
          </div>
          <div class="layout-bottom__item" @click="onClickMe">
            <div
              class="layout-bottom__item__icon"
              :style="`background: url('/images/${
                nowRoute.indexOf('/user') === 0 ? 'meSelect' : 'meIcon'
              }.png') center center / contain no-repeat`"
            ></div>
            <div class="layout-bottom__item__title">ME</div>
          </div>
        </div>
      </el-affix>
    </div>
    <div v-else>
      <el-affix
        class="layout_top_affix"
        target="#__nuxt"
        :offset="0"
        :z-index="112"
      >
        <section class="site-header" id="siteHeader">
          <div class="nav_tips">
            <span
              class="nav_tips_text"
              v-if="topBanner && topBanner.length"
              @click="toNewPage(topBanner[0].interlinkage)"
            >
              <span v-for="(item, index) in topBanner" :key="index">
                {{ item.title }}
              </span>
              >>
            </span>
          </div>
          <div class="header-bottom pc-container">
            <div class="container">
              <div class="header-pc">
                <div class="header-pc-line">
                  <div class="header-pc-line-leftmoney">
                    <div class="pt_10">
                      <client-only>
                        <el-dropdown trigger="click" @command="handleCurrency">
                          <span
                            class="el-dropdown-link header-bottom-money fs_12"
                          >
                            <!-- <el-image style="width: 22px; margin-right: 8px" :src="currencyNow?.picture && currencyNow?.picture.length ? fileUrl + currencyNow.picture[0].storageKey : ''" fit="cover" /> -->
                            <span style="padding-right: 6px">
                              <span class="currency-country-part">{{ currencyNow?.country }}</span> {{ currencyNow?.currency }}
                            </span>
                            <el-icon class="el-icon--right">
                              <ArrowDown />
                            </el-icon>
                          </span>
                          <template #dropdown v-if="currencyRate" >
                            <el-dropdown-menu >
                              <el-dropdown-item
                                v-for="(item, index) in currencyRate ?? []"
                                :key="index"
                                :command="item"
                              >
                                <!-- <el-image style="width: 22px; height: 22px; margin-right: 8px" src="https://assets.jewlr.com/images/icons/flags/ca_rd.svg" fit="cover" /> -->
                                <el-image
                                  style="
                                    width: 22px;
                                    margin-right: 8px;
                                    display: flex;
                                  "
                                  :src="
                                    item?.picture && item?.picture.length
                                      ? fileUrl + item.picture[0].storageKey
                                      : ''
                                  "
                                  fit="cover"
                                />
                                {{ item?.country  }} ( {{item?.currency}})
                              </el-dropdown-item>
                            </el-dropdown-menu>
                          </template>
                        </el-dropdown>
                      </client-only>
                      <!-- <v-menu offset-y>
                        <template #activator="{ props }">
                          <span v-bind="props">USD</span>
                          <span class="iconfont icon-xialajiantou"></span>
                        </template>
                        <v-list>
                          <v-list-item v-for="(item, index) in items" :key="index" @click="">
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu> -->
                      <span
                        class="fs_12"
                        style="color: black; padding-left: 58px"
                        ><a
                          style="color: black; text-decoration: none"
                          href="mailto:service@msblue.com"
                          >Contact us: service@msblue.com</a
                        ></span
                      >
                    </div>
                  </div>
                  <div class="header-pc-line-logo header-pc-line-logo-margin">
                    <NuxtLink to="/">
                      <div class="flex ai_ct jc_ct">
                        <img
                          class="top_logo"
                          srcset="
                            /images/topLogo.png      750w,
                            /images/topLogo_new.png 1200w
                          "
                          src="/images/topLogo_new.png"
                        />
                      </div>
                    </NuxtLink>
                  </div>
                  <div class="header-pc-line-rightbtn">
                    <div class="header_right_wrap flex jc_fe ai_ct">
                      <!-- 左侧搜索 -->
                      <div class="search_wrap search_left" style="width: 180px">
                        <el-input
                          v-model="searchText"
                          class="search_wrap-input"
                          clearable
                          placeholder="Search entire store here"
                          @keydown.enter="handleSearch(searchText)"
                        >
                          <!-- <template #suffix>
                            <div class="right-input-icon" @click="handleSearch(searchText)"></div>
                          </template> -->
                        </el-input>
                        <div
                          class="right-input-icon"
                          @click="handleSearch(searchText)"
                        ></div>
                      </div>

                      <client-only>
                        <!-- Search PC 浮动模式 -->
                        <el-popover
                          placement="bottom-end"
                          clearable
                          :width="400"
                          trigger="hover"
                          popper-class="click-search"
                          :hide-after="100"
                          transition="notransition"
                        >
                          <el-input
                            v-model="searchText"
                            class="search_block"
                            @change="handleSearch"
                            :prefix-icon="Search"
                            placeholder="Search entire store here"
                          />
                          <template #reference>
                            <img
                              class="search_icon btn-badge icon-block-search"
                              src="/images/search_icon_1.png"
                            />
                          </template>
                        </el-popover>
                      </client-only>
                      <client-only>
                        <!-- 个人中心 -->
                        <div
                          @click="onUserCenter"
                          class="btn-badge"
                          style="cursor: pointer"
                        >
                          <el-badge
                            :value="unreadNumber"
                            :hidden="!unreadNumber"
                            :max="99"
                          >
                            <img
                              class="user_icon icon-block"
                              src="/images/user_icon_1.png"
                            />
                          </el-badge>
                        </div>
                        <el-drawer
                          v-model="userDrawer"
                          title=""
                          :direction="direction"
                          :before-close="handleClose"
                          :modal="true"
                          :show-close="false"
                          :with-header="false"
                        >
                          <div class="menu-top-dropdown">
                            <div class="menu-top-avatar">
                              <!-- <el-avatar
                                class="ro_avatar"
                                shape="square"
                                size="large"
                                style="background-color: transparent"
                                :src="userAvatar(userInfo)"
                              /> -->
                              <UserTiers :userInfo="userInfo" roundedEdges style="margin-left: 16px" />
                              <div class="menu-top-avatar-text">
                                <div class="menu_user_name">
                                  {{
                                    `${userInfo.firstName || ""}  ${
                                      userInfo.lastName || ""
                                    }`
                                  }}
                                </div>
                                <div class="menu_user_money">
                                  {{ userInfo.score }} GEMS =
                                  {{ filterCurrency(userInfo.USD) }}
                                </div>
                              </div>
                            </div>
                            <el-divider style="margin:0" />
                            <div
                              :class="setOverflowHeight ? 'setOverflow' : ''"
                              :style="
                                setOverflowHeight
                                  ? `max-height: ${setOverflowHeight}px`
                                  : ''
                              "
                            >
                              <div
                                style="padding: 0 20px; cursor: pointer"
                                v-for="(item, index) in userMenuList"
                                :key="index"
                                @click="toUserPage(item.url)"
                              >
                                <div
                                  :class="[
                                    'menu-top-user',
                                    index === 0 ? '' : 'menu-top-user_line',
                                  ]"
                                >
                                  {{ item?.name }}
                                </div>
                              </div>
                            </div>
                            <el-divider />
                            <div class="logout_wrap" @click="userLogout()">
                              <img
                                class="logout_icon_wrap"
                                src="~/assets/img/logout_icon.png"
                              />
                              <span class="logout_text">LOGOUT</span>
                            </div>
                          </div>
                        </el-drawer>
                        <!-- 收藏 -->
                        <div
                          @click="collectDrawer = true"
                          class="btn-badge btn-badge_margin d-flex align-center"
                        >
                          <!-- <el-badge :value="collectTotal" :hidden="!collectTotal" :max="99"> -->
                          <img
                            class="heart_icon icon_block"
                            v-if="!collectTotal"
                            src="/images/weiai.png"
                          />
                          <img
                            class="heart_icon icon_block"
                            v-if="collectTotal"
                            src="/images/youcollectTotal.png"
                          />
                          <!-- </el-badge> -->
                          <span
                            class="ml-2"
                            style="font-size: 18px; color: #000; line-height: 0"
                            v-if="collectTotal"
                            >{{ collectTotal }}</span
                          >
                        </div>
                        <el-drawer
                          v-model="collectDrawer"
                          title=""
                          :direction="direction"
                          :before-close="handleClose"
                          :modal="true"
                          :show-close="false"
                          :with-header="false"
                        >
                          <div class="menu-top-dropdown">
                            <div class="menu-top-avatar">
                              <span class="FAVORITES_txt">MY WISHLIST</span>
                            </div>

                            <el-divider />

                            <div v-if="collectList && collectList.length > 0">
                              <div class="menu-top-dropdown-block" style="margin-bottom: 90px;">
                                <div
                                  v-for="(item, index) in collectList"
                                  :key="index"
                                  @click="
                                    () =>
                                      router.replace(
                                        `/products/${item?.urlComponent || item?.url_component}`
                                      )
                                  "
                                  class="menu-top-dropdown-line"
                                  style="padding: 0 20px; cursor: pointer"
                                >
                                  <div
                                    :class="[
                                      'collect_item_wrap flex ai_ct',
                                      index === 0 ? '' : 'menu-top-user_line',
                                    ]"
                                  >
                                    <div class="flex ai_ct flex_g_1">
                                      <el-image
                                        class="collect_img_item"
                                        :src="
                                          item?.commodityImage &&
                                          item?.commodityImage.length
                                            ? fileUrl +
                                              item?.commodityImage[0]
                                                ?.storageKey
                                            : ''
                                        "
                                        fit="contain"
                                      />
                                      <div class="collect_txt_wrap flex fd_c">
                                        <p class="commodity_name">
                                          {{ item.commodityName || "-" }}
                                        </p>
										<span class="special-price pt-2">{{
                                          filterCurrency(getSalePrice(item))
                                        }}</span>
                                        <span class="origina-price pt-2">
                                          <span
                                            v-if="
                                              item?.referencePrice &&
                                              item?.referencePrice >
                                                item?.minPrice
                                            "
                                            >{{
                                              filterCurrency(
                                                getOriginalPrice(item)
                                              )
                                            }}</span
                                          >
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="menu-top-dropdown-line-right flex_s_0"
                                    >
                                      <img
                                        class="remove_icon_img"
                                        @click.stop="delCollect(item.id, index)"
                                        src="~/assets/img/c_close_icon.png"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div style="padding: 0 10px">
                                
                              </div>
                              <div style="padding: 0 20px; cursor: pointer; position: fixed; bottom: 0; background-color: #FFF; width: 500px;">
                                <div class="menu-top-dropdown-btns">
                                  <div
                                    style="
                                      background-color: white;
                                      color: black;
									  width: 200px;
                                    "
                                    class="menu-top-dropdown-btn flex_g_1"
                                    @click="delAllCollect()"
                                  >
                                    Clear All
                                  </div>
                                  <div
                                    style="width: 10px; height: 100%;"
                                    class="flex_s_0"
                                  ></div>
                                  <div
                                    class="menu-top-dropdown-btn flex_g_1"
									style=" width: 200px;"
                                    @click="
                                      jump('/wishlist'), (collectDrawer = false)
                                    "
                                  >
                                    Check All
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else class="collect-empty">
                              <el-empty
                                description="Your wishlist is empty."
                              ></el-empty>
                            </div>
                          </div>
                        </el-drawer>

                        <!-- 购物车 -->
                        <div @click="showCartDrawer" class="btn-badge">
                          <!-- <el-badge  :value="shoppingList ? shoppingList.length : 0" :hidden="!shoppingList || !shoppingList.length" :max="99"> -->
                          <img
                            class="shop_car_icon icon_block"
                            v-if="shoppingList.length == 0"
                            src="/images/wuyoushoppig.png"
                          />
                          <img
                            class="shop_car_icon icon_block"
                            v-if="!shoppingList.length == 0"
                            src="/images/youshoppig.png"
                          />
                          <!-- <img class="shop_car_icon icon_block" src="/images/wuyoushoppig.png" /> -->
                          <!-- </el-badge> -->
                        </div>
                        <el-drawer
                          v-model="cartDrawer"
                          title=""
                          :direction="direction"
                          :before-close="handleClose"
                          :modal="true"
                          :show-close="false"
                          :with-header="false"
                          :size="495"
                        >
                          <div class="menu-top-dropdown">
                            <div class="menu-top-avatar">
                              <span class="FAVORITES_txt"
                                >MY Shopping Cart</span
                              >
                              <div class="drawer-title-right-badge">
                                <el-badge
                                  :value="shoppingTotal"
                                  :hidden="!shoppingTotal"
                                  :max="99"
                                >
                                  <!-- <img src="~/assets/shop_car_icon.svg" /> -->
                                  <img src="/images/wuyoushoppig.png" />
                                </el-badge>
                              </div>
                            </div>
                            <div v-if="shoppingList && shoppingList.length > 0">
                              <div class="menu-top-dropdown-block" style="margin-bottom: 180px;">
                                <div
                                  style="padding: 0 20px; cursor: pointer"
                                  class="menu-top-dropdown-line"
                                  v-for="(item, index) in shoppingList"
                                  :key="index"
                                >
                                  <div
                                    :class="[
                                      'collect_item_wrap flex ai_ct',
                                      index === 0 ? '' : 'menu-top-user_line',
                                    ]"
                                  >
                                    <div class="flex flex_g_1">
                                      <el-image
                                        class="collect_img_item"
                                        :src="
                                          item?.goodsInformation?.goodsImage &&
                                          item?.goodsInformation?.goodsImage
                                            .length
                                            ? fileUrl +
                                              item.goodsInformation
                                                .goodsImage[0].storageKey
                                            : ''
                                        "
                                        fit="contain"
                                      />
                                      <div class="collect_txt_wrap flex fd_c">
                                        <div class="commodity_name">
                                          {{
                                            item?.goodsInformation?.goodsName
                                          }}
                                        </div>
                                        <!-- v-if="item?.goodsInformation ?.goodsCategory === '0'" -->
                                        <div>
                                          <NumberInputPad
                                            :min="1"
                                            :value="item?.quantity || 1"
                                            @update:value="
                                              onShoppingQuantityChange(
                                                item,
                                                $event
                                              )
                                            "
                                            style="width: 93px"
                                          />
                                        </div>
                                        <!-- <div v-else class="spec-info">
                                          {{ item?.specInfo }}
                                        </div> -->

                                        <span class="special-price">
                                          {{
                                            filterCurrency(getGoodsPrice(item))
                                          }}
                                          <!-- {{ getQuatity(item) }} -->
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      class="menu-top-dropdown-line-top-right"
                                      @click.stop="delShopping(item, index)"
                                    >
                                      <img
                                        class="remove_icon_img"
                                        src="~/assets/img/c_close_icon.png"
                                      />
                                    </div>

                                    <!-- <div class="menu-top-dropdown-line-right">
                                      <el-icon @click.stop="delShopping(item.id, index)"><CloseBold /></el-icon>
                                    </div> -->
                                  </div>
                                </div>
                              </div>
                              <div style="padding: 0 20px">
                                <el-divider class="center_line" />
                              </div>
                              <div style="padding: 0 20px; cursor: pointer; position: fixed; bottom: 0; width: 500px; background-color: #FFF;">
                                <div
                                  class="menu-top-bottom"
                                  style="padding-bottom: 20px;"
                                >
                                  <!-- <div class="menu-top-dropdown-btns">
                                    <div class="menu-top-dropdown-btn" @click="delAllGoods()">清空购物车</div>
                                    <div class="menu-top-dropdown-btn">分享购物车</div>
                                  </div> -->
                                  <!-- <div class="menu-top-dropdown-btns">
                                    <div>购物车小计</div>
                                    <div>{{ shoppingData?.goodsMoney ? filterCurrency(shoppingData.goodsMoney) : '' }}</div>
                                  </div>
                                  <div class="menu-top-dropdown-btns">
                                    <div>优惠价</div>
                                    <div>{{ shoppingData?.goodsDiscount ? filterCurrency(shoppingData.goodsDiscount) : '' }}</div>
                                  </div> -->
                                  <!-- <div class="menu-top-dropdown-btns shop_car_text">
                                    <div>Total:</div>
                                    <div>{{ shoppingData?.goodsSubtotal ? filterCurrency(shoppingData.goodsSubtotal) : filterCurrency(0) }}</div>
                                  </div> -->
                                  <div
                                    class="side-btn-primary"
                                    style="background-color: #faa21b; color: white;"
                                    @click="onCheckOut"
                                    v-loading="checkOutLoading"
                                  >
                                    Check Out
                                  </div>
                                  <div
                                    class="side-btn-primary"
                                    style="border: solid 1px #000;"
                                    @click="onViewCard"
                                  >
                                    View Cart
                                  </div>
                                  
                                </div>
                              </div>
                            </div>
                            <div v-else class="collect-empty">
                              <el-empty
                                description="Your shopping cart is empty."
                              ></el-empty>
                            </div>
                          </div>
                        </el-drawer>
                        <span
                          class="ml-2"
                          style="font-size: 18px; color: #000"
                          v-if="shoppingTotal"
                          >{{ shoppingTotal }}</span
                        >
                      </client-only>
                      <!-- 0725 UI图上没有了，去掉了 -->
                      <!-- 右侧搜索 -->
                      <!-- <div class="search_wrap search_right">
                        <el-input
                          v-model="searchText"
                          class="search_wrap-input"
                          clearable
                          placeholder="Search entire store here"
                          @change="handleSearch"
                        >
                          <template #suffix>
                            <div class="right-input-icon" @click="handleSearch(searchText)"></div>
                          </template>
                        </el-input>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="header_mb">
                <div class="menu_icon_wrap flex_s_0" @click="openMenu">
                  <img src="~/assets/menu_icon.svg" class="menu_icon" />
                </div>

                <div class="header_mb_logo">
                  <NuxtLink to="/">
                    <!-- <img class="top_logo"
                      src="/images/topLogo.png" /> -->
                    <img
                      class="top_logo"
                      srcset="
                        /images/topLogo.png     750w,
                        /images/topLogo@2x.png 1200w
                      "
                      src="/images/topLogo@2x.png"
                    />
                  </NuxtLink>
                </div>

                <div class="header_mb_rightbtn">
                  <!-- <div class="mb-icon" @click="mobileSearch = !mobileSearch" >
                    <el-badge hidden>
                      <img class="search_icon_m" src="~/assets/search_icon_m.svg" />
                    </el-badge>
                  </div> -->

                  <div class="mb-icon" @click="jump('/user/instrument')">
                    <el-badge
                      :value="unreadNumber"
                      :hidden="!unreadNumber"
                      :max="99"
                    >
                      <img class="user_icon" src="~/assets/user_icon.svg" />
                    </el-badge>
                  </div>

                  <div class="mb-icon" @click="jump('/wishlist')">
                    <el-badge
                      :value="collectTotal"
                      :hidden="!collectTotal"
                      :max="99"
                    >
                      <img class="heart_icon" src="~/assets/heart_icon.svg" />
                    </el-badge>
                  </div>

                  <div class="mb-icon" @click="jump('/cart')">
                    <el-badge
                      :value="shoppingList ? shoppingList.length : 0"
                      :hidden="!shoppingList || !shoppingList.length"
                      :max="99"
                    >
                      <img
                        class="shop_car_icon"
                        src="~/assets/shop_car_icon.svg"
                      />
                    </el-badge>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <NavigationBar class="menu-top-center" />
        </section>

        <div class="header-mobild-search">
          <!-- v-show="mobileSearch" -->
          <el-input
            v-model="searchText"
            class="search_wrap-input"
            clearable
            placeholder="Search entire store here"
            @change="handleSearch"
          >
            <template #suffix>
              <div
                class="right-input-icon"
                @click="handleSearch(searchText)"
              ></div>
            </template>
          </el-input>
        </div>
      </el-affix>
      <slot />
      <footer class="footer_wrap">
        <div class="footer_wrap_top">
          <div class="flex_g_1 email_wrap_right bg_gra">
            <ul class="flex jc_sb footer-list-menu">
              <li class="footer_item_wrap email_wrap">
                <div class="email_wrap_box">
                  <div class="email_wrap_title">
                    SIGN UP FOR SPECIAL OFFERS!
                  </div>
                  <div class="email_wrap_content">
                    Join our VIP list AND shop today with $25 off your FIRST
                    ORDER $99+ storewide.
                  </div>
                  <div class="flex ai_ct jc_ct email_input_wrap">
                    <el-input
                      v-model="emailAddress"
                      placeholder="Email Address"
                      clearable
                    >
                      <template #append>
                        <button
                          :disabled="bottomUserRegistratioLoading"
                          @click="bottomUserRegistration"
                          class="email_submit_btn"
                        >
                          Submit
                        </button>
                      </template>
                    </el-input>
                  </div>                  
                  <div class="email_wrap_title">FOLLOW US ON</div>
                  <div class="email_wrap_image">
                    <NuxtLink
                      v-for="(item, index) in bottomMenuFollow"
                      :key="index"
                      class="block-click"
                      :to="item.interlinkage"
                      target="_blank"
                    >
                      <div v-if="item?.advertisementImage?.[0]?.raw" v-html="item.advertisementImage[0].raw"></div>
                      <img
                        v-else-if="
                          item?.advertisementImage &&
                          item?.advertisementImage.length
                        "
                        :src="getFileUrl(item?.advertisementImage)"
                        alt=""
                        loading="lazy"
                      />
                    </NuxtLink>
                  </div>
                </div>
              </li>

              <li class="footer_item_wrap footer_item_pc">
                <div class="footer_header">CONTACT US</div>
                <ul>
                  <li
                    class="footer_child_wrap"
                    v-for="(subItem, idx) in contactUs"
                    :key="idx + 'footerChild'"
                  >
                    <!-- <a class="footer_child_item" v-if="subItem?.url">{{ subItem.title }}</a> -->
                    <img
                      v-if="idx == 0"
                      style="
                        width: 16px;
                        height: 12.4px;
                        position: absolute;
                        margin-top: 8px;
                      "
                      src="~/assets/img/icon_text_msg.png"
                    />
                    <img
                      v-else
                      style="
                        width: 16px;
                        height: 12px;
                        position: absolute;
                        margin-top: 6px;
                      "
                      src="~/assets/img/icon_text_email.png"
                    />
                    <span
                      class="footer_child_item"
                      style="margin-left: 30px"
                      @click="toPage(subItem?.interlinkage)"
                      >{{ subItem.title }}</span
                    >
                  </li>
                </ul>
              </li>
              <li class="footer_item_wrap footer_item_pc">
                <div class="footer_header">ABOUT MSBLUE</div>
                <ul>
                  <li
                    class="footer_child_wrap"
                    v-for="(subItem, idx) in bottomMenuGYWM"
                    :key="idx + 'footerChild'"
                  >
                    <!-- <a class="footer_child_item" v-if="subItem?.interlinkage">{{ subItem.title }}</a> -->
                    <NuxtLink
                      class="footer_child_item"
                      :to="subItem?.interlinkage"
                      >{{ subItem.title }}</NuxtLink>
                  </li>
                </ul>
              </li>
              <li class="footer_item_wrap footer_item_pc">
                <div class="footer_header">EDUCATION</div>
                <ul>
                  <li
                    class="footer_child_wrap"
                    v-for="(subItem, idx) in bottomMenuPPJY"
                    :key="idx + 'footerChild'"
                  >
                    <!-- <a class="footer_child_item" v-if="subItem?.url">{{ subItem.title }}</a> -->
                    <NuxtLink
                      class="footer_child_item"
                      :to="subItem?.interlinkage"
                      >{{ subItem.title }}</NuxtLink
                    >
                  </li>
                </ul>
              </li>
              <li class="footer_item_wrap footer_item_pc">
                <div class="footer_header">CUSTOMER CARE</div>
                <ul>
                  <li
                    class="footer_child_wrap"
                    v-for="(subItem, idx) in bottomMenuKHFW"
                    :key="idx + 'footerChild'"
                  >
                    <!-- <a class="footer_child_item" v-if="subItem?.url">{{ subItem.title }}</a> -->
                    <NuxtLink
                      class="footer_child_item"
                      :to="subItem?.interlinkage"
                      >{{ subItem.title }}</NuxtLink>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="footer_menu">
            <el-collapse v-model="activeFooterMenu" accordion>
              <el-collapse-item
                class="bg-gray email_wrap_title"
                title="CONTACT US"
                :name="1"
              >
                <div
                  class="left-menu-list-line"
                  v-for="(item, index) in contactUs"
                  :key="index"
                  @click="toPage(item?.interlinkage)"
                >
                  {{ item.title }}
                </div>
              </el-collapse-item>
              <el-collapse-item
                class="bg-gray email_wrap_title"
                title="ABOUT MSBLUE"
                :name="2"
              >
                <div
                  class="left-menu-list-line"
                  v-for="(item, index) in bottomMenuGYWM"
                  :key="index"
                  @click="toPage(item?.interlinkage)"
                >
                  {{ item.title }}
                </div>
              </el-collapse-item>
              <el-collapse-item
                class="bg-gray email_wrap_title"
                title="EDUCATION"
                :name="3"
              >
                <div
                  class="left-menu-list-line"
                  v-for="(item, index) in bottomMenuPPJY"
                  :key="index"
                  @click="toPage(item?.interlinkage)"
                >
                  {{ item.title }}
                </div>
              </el-collapse-item>
              <el-collapse-item
                class="bg-gray email_wrap_title"
                title="CUSTOMER CARE"
                :name="4"
              >
                <div
                  class="left-menu-list-line"
                  v-for="(item, index) in bottomMenuKHFW"
                  :key="index"
                  @click="toPage(item?.interlinkage)"
                >
                  {{ item.title }}
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>

        <div class="owner_txt_wrap">
          <span>© {{ copyRightYear }} MSBLUE JEWELRY - All Right reserved.</span>
        </div>
        <div class="brand_icon_wrap">
          <ul class="flex ai_ct jc_ct brand_icon_wrap_item_parent">
            <li
              class="brand_icon_wrap_item"
              v-for="(item, idx) in footer_brand_img"
              :key="'brand_icon_wrap' + idx"
            >
              <span :href="item.url">
                <img class="brand_icon_wrap_item_img block" :src="item.img" />
              </span>
            </li>
          </ul>
        </div>
      </footer>
    </div>
    <client-only>
      <!-- 登录窗口 -->
      <el-dialog
        v-model="loginVisible"
        class="login-dialog"
        :show-close="false"
        :close-on-click-modal="false"
        lock-scroll
      >
        <login-popup
          :loginAdList="loginAdList"
          @cancel="closeLogin"
        ></login-popup>
      </el-dialog>
    </client-only>

    <client-only>
      <el-dialog
        v-model="registrationDialog.open"
        lock-scroll
        align-center
        :custom-class="
          registrationDialog.type
            ? 'registrationDialog--success registrationDialog'
            : 'registrationDialog'
        "
      >
        <div class="registrationDialog-header">
          <img
            src="~/assets/img/c_close_icon.png"
            @click="registrationDialog.open = false"
          />
        </div>
        <div :class="['registrationDialog-body']">
          <div class="registrationDialog-body__left">
            <img
              v-if="registrationDialog.type"
              src="~/assets/success-icon.png"
            />
            <img v-else src="~/assets/error-icon.png" />
          </div>

          <div class="registrationDialog-body__right">
            <template v-if="registrationDialog.type">
              <div class="item-title">
                <span>USE CODE:</span>
                <span class="item-title__code"> NEW25</span>
                <img src="~/assets/copy-icon.png" @click="copyText('NEW25')" />
                <span class="item-title__label" @click="copyText('NEW25')"
                  >Copy</span
                >
              </div>

              <div v-if="['xs'].includes(sizeMode)" class="item-content">
                Congratulations!<br />
                Your welcome gift is $25 off. <br />
                Usecode NEW25 at shopping cart.<br />
                TODAY ONLY!<br />
                On your first purchase of $100 or more
              </div>
              <div v-else class="item-content">
                Congratulations!<br />
                Your welcome gift is $25 off. Usecode NEW25 at shopping cart.
                TODAY ONLY! On your first purchase of $100 or more
              </div>
            </template>
            <template v-else>
              <div class="item-error">
                <span>{{ registrationDialog.errorMsg }}</span>
              </div>
            </template>
          </div>
        </div>
      </el-dialog>
    </client-only>
    <client-only>
      <el-backtop>
        <el-icon><ArrowUpBold /></el-icon>
      </el-backtop>
    </client-only>
  </div>
</template>
<style scoped lang="scss">
@import "@/assets/styles/default.scss";

.layout_top_affix {
  width: 100% !important;
}
.layout_top_affix .el-affix--fixed {
  width: 100% !important;
}
// :deep {
:deep(.el-affix--fixed) {
  height: fit-content !important;
}
:deep(.el-overlay.left-menu-overlay) {
  // top: 128px;
  top: 108px;
}
// }

:focus-visible {
  outline: -webkit-focus-ring-color auto 0px;
}

@keyframes inAnimation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}
.menu-second-enter,
.menu-second-leave-to {
  // transform: translateX(100%);
  animation: inAnimation 0.5s reverse;
}
.menu-second-leave,
.menu-second-enter-to {
  // transform: translateX(50%);
  animation: inAnimation 0.5s;
}
// .menu-second-enter-active, .menu-second-leave-active {
// 	transition: all .3s;
// 	transition: 0.5s linear;
// }
.container {
  width: 100%;
}

.site-bottom {
  display: none;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 18px;
  padding-top: 15px;
  border-top: 4px solid #78d9e1;
  background-color: #ffffff;
  @media (max-width: 600px) {
    padding-bottom: 14px;
    padding-top: 10px;
    border-top: 2px solid #78d9e1;
    .site-bottom-block {
      &-img {
        width: 28px;
        height: 28px;
      }
      &-title {
        font-size: 12px;
      }
    }
  }
  &-piece {
    width: 20%;
    display: flex;
    justify-content: center;
  }
  &-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    &:hover {
      cursor: pointer;
    }
    &-img {
      width: 40px;
      height: 40px;
    }
    &-title {
      font-size: 16px;
      font-family: gothic;
      color: #999999;
    }
  }
  @media (max-width: 1200px) {
    display: flex;
  }
}

.left-menu-container {
  .left-menu-second {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100vh;
    padding: 0 20px;
    background-color: #ffffff;
    // :deep {
    :deep(.el-collapse-item__arrow.is-active) {
      transform: unset;
    }
    :deep(.el-collapse-item__arrow.is-active svg path) {
      d: path(
        "M863.74455 544.00086 163.424056 544.00086c-17.664722 0-32.00086-14.336138-32.00086-32.00086s14.336138-32.00086 32.00086-32.00086l700.320495 0c17.695686 0 31.99914 14.336138 31.99914 32.00086S881.440237 544.00086 863.74455 544.00086z"
      ) !important;
    }
    :deep(.el-collapse-item__arrow svg path) {
      d: path(
        "M791.457164 482.009422 542.361553 482.009422 542.361553 232.913811c0-17.190122-13.94987-31.139992-31.139992-31.139992-17.190122 0-31.139992 13.94987-31.139992 31.139992l0 249.095611L230.985958 482.009422C213.795836 482.009422 199.845966 495.959292 199.845966 513.149414c0 17.190122 13.94987 31.139992 31.139992 31.139992l249.095611 0 0 249.095611c0 17.224179 13.94987 31.139992 31.139992 31.139992 17.190122 0 31.139992-13.915813 31.139992-31.139992L542.361553 544.289406l249.095611 0c17.224179 0 31.139992-13.94987 31.139992-31.139992C822.597156 495.959292 808.681343 482.009422 791.457164 482.009422L791.457164 482.009422zM791.457164 482.009422"
      ) !important;
    }
    // }
    &-title {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      text-align: center;
      width: 100%;
      height: 80px;
      line-height: 80px;
      position: relative;
      font-size: 16px;
      i {
        position: absolute;
        left: 2px;
        top: 50%;
        transform: translate(0%, -50%);
        cursor: pointer;
      }
    }
    .el-collapse-item-title {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }
  .left-menu-top {
    width: 100%;
    padding: 10px 20px;
    height: 90px;
    background-color: #d7ecf0;
    display: flex;
    align-items: center;
    &-image {
      width: 72px;
      height: 72px;
      background-color: #2cc4d2;
    }
    &-text {
      margin-left: 10px;
      &-name {
        font-size: 30px;
        color: #000000;
        font-family: "ivyLight";
      }
      &-money {
        padding-top: 4px;
        font-size: 18px;
        color: #dc226b;
        font-family: gothic;
      }
    }
    // &-login {
    // 	font-size: 30px;
    // 	color: #000000;
    // 	font-family: "ivyLight";
    // }
    // @media (max-width: 600px) {
    // 	height: 64px;
    // 	padding: 10px 16px;
    // 	&-image {
    // 		width: 50px;
    // 		height: 50px;
    // 	}
    // 	&-text {
    // 		margin-left: 14px;
    // 		&-name {
    // 			font-size: 20px;
    // 		}
    // 		&-money {
    // 			font-size: 12px;
    // 		}
    // 	}
    // }
  }
  .left-menu-list {
    padding: 0 20px;
    &-btn {
      width: 240px;
      height: 54px;
      border-radius: 4px;
      background-color: #29a2b4;
      color: #ffffff;
      user-select: none;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 15px 0;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    &-line {
      // background-color: #F5F5F5;
      padding: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      user-select: none;
      cursor: pointer;
      &:active,
      &:hover {
        opacity: 0.8;
      }
      div {
        display: flex;
        align-items: center;
      }
    }
    // :deep {
    :deep(.el-collapse-item__header) {
      // font-size: 16px;
      border-bottom: 0;
      color: #000;
      margin: 10px 0;
      font-size: 24px;
      font-family: gothic;
    }
    :deep(.el-collapse) {
      border-top: 0;
      border-bottom: 0;
    }
    :deep(.el-collapse-item__arrow) {
      margin-right: 0;
    }
    // }
  }

  .left-menu-content {
    padding: 15px 20px;
  }
  .left-menu-logout {
    width: 100%;
    height: 90px;
    display: flex;
    align-items: center;
    background-color: #d7ecf0;
    font-family: gothic;
    font-size: 24px;
    &-icon {
      width: 30px;
      height: 30px;
      margin-right: 30px;
      background: url("/public/images/menuLogout.png") center center / 100%
        no-repeat;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .left-menu-msg {
    font-size: 18px;
    color: #29a2b4;
    font-family: gothic;
    p {
      line-height: 38px;
    }
  }
  .left-menu-text {
    font-size: 14px;
    font-family: gothic;
    color: #000000;

    p {
      line-height: 38px;
    }
  }
}

// :deep {
:deep(.el-collapse-item__wrap) {
  max-height: 50vh;
  overflow-y: auto;
  padding-left: 20px;
}
:deep(.el-collapse-item__header) {
  font-size: 0.16rem;
}
// }

.menu_block {
  // margin: 0 auto;
  width: 100%;
}

.menu_nav_wrap {
  display: flex;
  padding: 0 472px;
  margin: 0 auto;
}

.menu_block_item {
  font-size: 14px;
  height: 56px;
  cursor: pointer;
  // line-height: 50px;
  .menu_block_item_span {
    // padding: 0 20px;
    &:hover {
      color: #04a9c7;
    }
  }
}

.header-pc {
  &-line {
    display: flex;
    justify-content: space-between;
  }
  &-line-logo-margin {
    margin-left: 43px;
  }
  .header_money_right {
    display: none;
    margin-left: 20px;
  }
  .search_right {
    display: none;
    margin-right: 0 !important;
    margin-left: 15px !important;
  }
  // .search_left {
  // 	display: block;
  // }
}
.header-mobild-search {
  padding: 10px 40px;
  background-color: #ffffff;
  // border-top: 1px solid #cccccc;
  // border-bottom: 1px solid #cccccc;
  @media (max-width: 600px) {
    padding: 10px 14px;
  }
}
.site-header {
  // z-index: 112;
  // position: sticky;
  // top: 0;
}
.nav_tips {
  // padding: 11px 15px 10px 15px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #29a2b4;
  width: 100%;
  color: #f8f8f8;
  text-align: center;
  font-size: 14px;
  .nav_tips_text {
    text-transform: uppercase;
    span:nth-child(odd) {
      font-weight: bold;
    }
    span:nth-child(even) {
      margin: 0 10px;
    }
    &:hover {
      border-bottom: 1px solid #f8f8f8;
      cursor: pointer;
    }
  }
}

.header-bottom {
  // height: 84px;
  margin: 0 auto;
  padding-left: 176px;
  padding-right: 176px;
  display: flex;
  padding-top: 30px;
  padding-bottom: 30px;
  // justify-content: center;
  align-items: center;
  color: $theme;
  background-color: #fff;
  @media (max-width: 1500px) {
    .top_logo {
      width: 160px !important;
      height: 34px !important;
      display: block;
      align-content: center;
    }
  }
  @media (max-width: 1300px) {
    .top_logo {
      width: 140px !important;
      height: 28px !important;
      display: block;
      align-content: center;
    }
  }
  @media (max-width: 1200px) {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }

  @media (max-width: 600px) {
    padding-left: 14px !important;
    padding-right: 14px !important;
  }
  // :deep {
  :deep(.el-dropdown) {
    vertical-align: inherit;
  }
  // }

  &-money {
    color: black;
    display: flex;
  }

  .top_logo {
    width: 160px;
    height: 32px;
    display: block;
  }

  .search_input {
    width: 100%;
    // height: 28px;
    padding: 4px 10px;
    padding-left: 14px;

    font-size: 14px;

    outline-style: none;
    border: 1px solid #cbcbcb;

    border-radius: 15px;
  }

  .search_icon {
    display: none;
    &:hover {
      cursor: pointer;
    }
  }

  .img_icon {
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    top: 50%;
    margin-top: -8px;
    right: 15px;
  }

  .header_right_wrap .btn-badge {
    line-height: 0;
  }

  .user_icon {
    display: block;
    height: 20px;
    // margin-left: 30px;
    // margin: 0px 15px;
  }
  .btn-badge_margin {
    margin: 0 24px;
  }
  .btn-badge {
    // margin: 0px 15px;

    &--small {
      margin: 0 4px;
    }
  }

  .heart_icon {
    display: block;
    height: 20px;
    // margin-left: 30px;
    // margin: 0px 15px;
    cursor: pointer;
  }

  .shop_car_icon {
    display: block;
    width: 18px;
    height: 20px;
    cursor: pointer;
    // margin-left: 30px;
    // margin: 0px 15px;
    // margin-right: 16px;
  }
  .icon-block {
    width: 18px;
    height: 20px;
  }
  .icon-block-search {
    width: 21px;
    height: 21px;
  }
}
.header-mobild-search {
  // :deep {
  :deep(.el-input__inner) {
    --el-input-inner-height: 30px;
    font-size: 14px;
    color: #666666;
    font-family: gothic;
  }
  // }
}

// 变小后的样式
.sm-site-header {
  .search_left {
    display: none;
  }
  .header-pc-line-leftmoney {
    display: none;
  }
  .header-bottom {
    padding: 10px 60px;
    @media (max-width: 1600px) {
      padding: 10px 45px;
    }
    @media (max-width: 1400px) {
      padding: 10px 30px;
    }
  }
  .menu-top-center {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    box-shadow: unset;
    background-color: unset;
  }
  .search_icon {
    display: block;
    margin-right: 20px;
  }
}

.search_wrap {
  max-width: 220px;
  min-width: 113px;
  position: relative;
  margin-right: 55px;
  // flex-grow: 1;
  // margin-right: 15px;
  // flex-basis: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  &-input {
    :deep() {
      .el-input__wrapper {
        padding: 0 0;
        border: none;
        border-radius: 0;
        box-shadow: 0 -1px 0 #000000 inset;
      }

      .el-input__inner {
        height: 24px;
        &::placeholder {
          color: #666666;
          font-size: 12px;
        }
      }
    }
  }
  .right-input-icon {
    width: 19.7px;
    height: 20.2px;
    align-self: flex-end;
    background: url("~/assets/img/search_icon_1.png") center center no-repeat;
    background-size: 19.7px 20.2px;
    background-position-x: inherit;
    &:hover {
      cursor: pointer;
    }
  }
}

.header-mobild-search {
  display: none;
}

.menu_icon_wrap {
  width: 23px;
  height: 18px;
  margin-right: 30px;
}

.menu_icon {
  width: 100%;
  height: 100%;
  display: block;
}

.header_mb {
  display: none;
  position: relative;
  align-items: center;
  &_rightbtn {
    display: flex;
  }

  .mb-icon {
    height: 24px;

    .el-badge {
      height: 24px;
      margin-left: 16px;
      // transform: translateY(-5px);

      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.footer_wrap {
  border-top: 8px solid #78d9e1;
  font-family: gothic;
}

.footer_header,
.email_wrap_title {
  font-family: gothicb;
}

.footer_wrap_top {
  display: flex;
  margin: 56px 176px 60px 176px;
  @media (max-width: 1499px) {
    // margin-left: 30px;
    // margin-right: 30px;
    .footer-list-menu {
      flex-wrap: wrap;
      gap: 24px 0;
      .email_wrap {
        flex-basis: 66.67%;
        padding-right: 12px;
      }
      .email_input_wrap {
        max-width: 300px;
        margin-bottom: 20px;
      }
      .footer_item_pc {
        flex-basis: 33.33%;
      }
    }
  }
  @media (max-width: 1199px) {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.email_wrap {
  flex-basis: 300px;
}

.footer_menu {
  display: none;
  margin-top: 20px;

  :deep(.el-collapse-item__header) {
    background-color: #fafafa !important;
  }

  :deep(.el-collapse-item__wrap) {
    background-color: #fafafa !important;
    font-family: gothic;
    font-weight: normal;
    cursor: pointer;
  }
}

.email_wrap_image {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  div {
    display: flex;
    align-items: center;
  }
}

.email_wrap_title {
  font-weight: bold;
  font-size: 16px;
  font-family: gothicb;
  // padding-bottom: 10px;
}

.email_wrap_content {
  font-size: 14px;
  padding-bottom: 22px;
  padding-top: 25px;
  line-height: 1.8;
}

.email_wrap_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.email_submit_btn {
  width: 81px;
  background-color: #78d9e1 !important;
  color: #fff !important;
  border-radius: 0;

  &:disabled {
    background-color: #999999 !important;
  }
}

.footer_header {
  font-weight: bold;
  font-size: 16px;
  padding-bottom: 15px;

  border: none;
}

.footer_child_item {
  color: #100e31;
  font-size: 14px;
}

.footer_child_item + .footer_child_item {
  padding-top: 20px;
}

.footer_child_wrap {
  margin-top: 10px;
  &:hover {
    cursor: pointer;
  }
}
.email_input_wrap {
  width: 100%;
  margin-bottom: 70px;

  :deep(.el-input-group__append) {
    padding: 0;
  }
}

.footer_item_wrap {
  min-width: 180px;
}

.bg_gray {
  background-color: #fafafa;
}

.owner_txt_wrap {
  padding-top: 6px;
  text-align: center;
  width: 100%;
}

.brand_icon_wrap {
  padding: 22px 15px 60px 15px;
  // max-width: 1400px;
}

.brand_icon_wrap_item {
}

.brand_icon_wrap_item_img {
  max-height: 24px;
}

.brand_icon_wrap_item + .brand_icon_wrap_item {
  margin-left: 12px;
}

.brand_icon_wrap_item_parent {
  flex-wrap: wrap;
}

// @media (max-width: 1200px) {
// 	.header-pc {
// 		// display: none;
// 	}

// 	.header_mb {
// 		// display: flex;
// 		justify-content: space-between;
// 		&_logo {
// 			position: absolute;
// 			top: 50%;
// 			left: 50%;
// 			transform: translate(-50%, -50%);
// 		}
// 		.top_logo {
// 			width: 130px;
// 		}
// 	}

// 	.header-mobild-search {
// 		// display: block;
// 	}

// 	.menu_nav_wrap_outter {
// 		// display: none;
// 	}
// 	.email_wrap_right {
// 		padding: 0 30px;
// 	}
// 	.footer_menu {
// 		display: block;
// 		padding: 0 30px;
// 		background-color: #FAFAFA;
// 	}
// 	.footer_wrap_top {
// 		display: block;
// 		padding: 60px 0px;
// 		margin: 0;
// 	}

// 	.footer_item_pc {
// 		// display: none;
// 	}

// 	.email_wrap {
// 		margin-right: 0;
// 		flex-basis: auto;
// 		width: 100%;
// 	}
// 	.owner_txt_wrap {
// 		// display: none;
// 	}

// 	.email_wrap_box {
// 		padding: 0px 0px;
// 	}

// 	.email_wrap_content {
// 		padding: 42px 0;
// 	}

// 	.email_wrap_image {
// 		justify-content: flex-start;
// 		img {
// 			margin-right: 54px;
// 		}
// 	}

// 	.email_input_wrap {
// 		width: 100%;

// 		// :deep {
// 			:deep(.el-input__inner) {
// 				--el-input-inner-height: 64px;
// 			}
// 		// }
// 		.email_submit_btn {
// 			width: 160px;
// 			font-size: 20px;
// 			color: #000000 !important;
//     	font-family: gothic;
// 			height: 100%;
// 		}
// 	}

// 	.left-menu-list-line {
// 		margin: 10px 0;
// 		font-size: 24px;
// 		font-family: gothic;
// 		@media (max-width: 600px) {
// 			font-size: 16px;
// 		}

// 		&.is-light {
// 			color: #00889C;
// 		}
// 	}

// 	.email_wrap_title {
// 		width: 100%;
// 		font-size: 24px;
// 	}
// }

// @media (max-width: 600px) {
// 	.header_mb {
// 		.top_logo {
// 			width: 88px;
// 		}
// 	}
// 	.email_wrap_title {
// 		font-size: 16px;
// 	}
// 	.email_input_wrap {
// 		// :deep {
// 			:deep(.el-input__inner) {
// 				--el-input-inner-height: 44px;
// 			}
// 		// }
// 		.email_submit_btn {
// 			width: 110px;
// 			font-size: 14px;
// 		}
// 	}
// 	.footer_menu {
// 		:deep(.el-collapse-item__header) {
// 			font-size: 16px;
// 		}
// 	}
// 	.email_wrap_image {
// 		img {
// 			margin-right: 36px;
// 		}
// 	}
// }
</style>
<style lang="scss">
.click-search {
  padding: 26px 20px !important;
}
.search_block {
  .el-input__wrapper {
    box-shadow: none;
    border-radius: 0;
    background-color: #f8f8f8;
    // border-bottom: 1px solid #d2d2d2;
  }
}

.menu_item_popover {
  left: 0 !important;
  // box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12) inset!important;
}
.menu_item_popover .el-popper__arrow {
  display: none !important;
}

// 顶部右上角悬浮
.menu-top-dropdown {
  .el-dropdown-menu__item {
    width: 495px;
  }
  .el-dropdown-menu__item:not(.is-disabled):focus {
    // background-color: unset;
    background-color: rgba($color: #000000, $alpha: 0.03);
    // background-color: rgba($color: #04A9C7, $alpha: 0.1);
    color: unset;
  }
  &-title {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
  }

  .menu-top-avatar {
    width: 100%;
    height: 80px;
    display: flex;
    align-items: center;
    background-color: #f5f5f5 !important;
    &-text {
      margin-left: 20px;
    }
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .menu-top-dropdown-block {
    max-height: 683px;
    overflow-y: auto;
  }
  .menu-top-dropdown-check {
    width: 100%;
    // background-color: #04A9C7;
    // border-radius: 4px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;

    &:hover {
      opacity: 0.8;
    }
  }

  .menu-top-bottom {
    width: 100%;
  }
  .menu-top-dropdown-line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      width: 100%;
    }
    .menu-top-dropdown-line-text {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      div {
        width: 204px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price-box {
        margin-top: 20px;
        display: flex;
        align-items: center;
        .origina-price {
          min-height: 14px;
          margin-left: 19px;
          font-size: 14px;
          font-family: gothic;
          font-weight: bold;
          color: #aaaaaa;
          text-decoration: line-through;
        }

        .sale-price {
          font-size: 14px;
          font-family: gothic;
          font-weight: bold;
          color: #dc226b;
        }
      }
    }
  }
  .menu-top-dropdown-btns {
    width: 100%;
    margin: 15px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menu-top-dropdown-btn {
      text-align: center;
      padding: 10px 20px;
      // border-radius: 4px;
      border: 1px solid #0c1737;
      background-color: #0c1737;
      color: white;
      // &:hover {
      // 	background-color: #04A9C7;
      // 	color: #ffffff !important;
      // }
    }
  }

  .menu-top-user {
    font-size: 16px;
    font-family: gothic;
    padding: 18px 0;
    width: 100%;
  }
  .menu-top-user:hover {
    color: #29a2b4;
  }
  .menu-top-user_line {
    border-top: 1px solid #e6e6e6;
  }
}

.menu-top-drop-avatar .el-dropdown-menu__item:first-child {
  font-weight: unset !important;
  color: #ffffff !important;
}
.menu-top-drop-avatar .el-dropdown-menu__item:not(.is-disabled):focus {
  background-color: unset;

  color: #29a2b4;
  font-weight: bold;
}

// ly -- 左侧菜单
.el-drawer__body {
  padding: 0 !important;
}
// 不写这个右侧会空17px
.el-popup-parent--hidden {
  width: unset !important;
}

.login-dialog {
  position: relative;
  width: fit-content !important;
  // @media (max-width: 768px) {
  // 	// margin-left: 20px;
  // 	// margin-right: 20px;
  // 	margin: 0;
  // }

  &-close {
    position: absolute;
    right: 8px;
    top: 6px;
    font-size: 24px;
    font-weight: bold;

    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 0 !important;
  }
}
.collect-empty {
  font-size: 18px;
  padding: 16px;
  color: #333;
}
.ro_avatar {
  width: 60px;
  height: 60px;
  margin-left: 31px !important;
}
.ro_avatar img {
  width: 100% !important;
  border-radius: 50%;
  border: 1px solid #2cc4d2;
  object-fit: contain !important;
}
.menu-top-avatar {
  padding: 9px 0;
  box-sizing: border-box;
}
.menu_user_name {
  font-family: ivyThin;
  font-weight: bold;
  font-size: 24px;
}
.ro_dropdown .el-popper__arrow::before {
  background-color: #d7ecf0 !important;
}
.menu_user_money {
  padding-top: 4px;
  font-size: 14px;
  font-family: gothic;
  color: #dc226b;
}
.ro_dropdown .el-dropdown-menu__item {
  padding: 0 32px;
}
.ro_dropdown_collect .el-dropdown-menu__item {
  padding: 0 10px;
}
.logout_wrap {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #d7ecf0;
  padding: 27px 30px;
  display: flex;
  align-items: center;
}
.logout_icon_wrap {
  width: 20.4px;
  height: 20px;
  display: block;
}
.logout_text {
  cursor: pointer;
  padding-left: 10px;
  color: #000;
  font-size: 16px;
  font-family: gothic;
  font-weight: bold;
}
.FAVORITES_txt {
  padding-left: 20px;
  font-size: 18px;
  font-family: gothic;
  font-weight: bold;
  text-transform: uppercase;
  color: #000000;
}
.drawer-title-right-badge {
  position: absolute;
  right: 40px;
  .el-badge__content {
    background-color: #dc226b;
    border-color: #dc226b;
    min-width: 18px;
    min-height: 14px;
    padding: 0;
    line-height: 14px;
    font-size: 12px;
  }
}
.remove_icon_img {
  width: 12px;
  height: 12px;
}
.collect_item_wrap {
  padding: 30px 10px;
  padding-right: 0;
  width: 100%;
}
.special-price {
  font-size: 19px;
  font-family: gothic;
  color: #000000;
}
.origina-price {
  min-height: 12px;
  font-size: 12px;
  font-family: gothic;
  color: #999999;
  text-decoration: line-through;
}
.collect_img_item {
  width: 160px;
  height: 160px;
  border: 1px solid #e6e6e6;
  margin-right: 21px;
}
.commodity_name {
  width: 179px;
  // height: 26px;
  font-size: 19px;
  font-family: gothic;
  font-weight: bold;
  color: #000000;

  // display: block;
  white-space: normal;
  display: -webkit-box;
  // word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box; /** 对象作为伸缩盒子模型显示 **/
  -webkit-box-orient: vertical; /** 设置或检索伸缩盒对象的子元素的排列方式 **/
  -webkit-line-clamp: 2; /** 显示的行数 **/
  overflow: hidden; /** 隐藏超出的内容 **/
}
.collect_txt_wrap {
  font-size: 19px;
  line-height: 25px;
  justify-content: space-between;

  .spec-info {
    font-size: 13px;
  }
}
.center_line {
  --el-border-color: #78d9e1;
}
.ro_dropdown_collect {
  min-width: 360px;
}
.shop_car_text {
  font-size: 18px;
  font-family: gothic;
}
.setOverflow {
  max-height: 483px;
  overflow-y: auto;
}
.menu-top-dropdown-line-top-right {
  align-self: flex-start;
  min-width: 24px;
  min-height: 24px;
  padding: 0 6px;
}
.side-btn-primary {
  margin-top: 20px;
  color: #000;
  line-height: 54px;
  text-align: center;
  font-size: 18px;
}

.header-pc-line-rightbtn {
  margin-top: 4px;
}

// 缩小模式菜单动态尺寸相关
.sm-site-header {
  .header-pc-line-logo,
  .header-pc-line-rightbtn {
    position: relative;
    z-index: 1;
  }
  // 1500px 以上
  @media (min-width: 1500px) {
    .menu-top-center {
      top: 46px!important;
    }
    .menu_block_item {   
      .menu_block_item_span {
        padding-bottom: 12px!important;
      } 
    }
  }
  // 1400px 以下
  @media (max-width: 1399px) {
    .menu-top-center {
      top: 46px!important;
    }
    .menu_block_item {
      height: 48px;
      .menu_block_item_span {
        padding-bottom: 3px!important;
      } 
    }
  }  
  // 1300px 以下
  @media (max-width: 1300px) {
    .header-pc-line-logo-margin {
      margin-left: 0;
    }
    .menu-top-center {
      top: 45px!important;
    }
    .menu_block_item {
      height: 46px;
      .menu_block_item_span {
        padding-bottom: 0!important;
      } 
    }
  }
  // 1300px 以下
  @media (max-width: 1200px) {
    .menu_block_item {
      .menu_block_item_span {
        padding-left: 12px!important;
        padding-right: 12px!important;
      } 
    }
  }
  // 1250px 以下
  @media (max-width: 1250px) {
    .header-pc-line-rightbtn {
      margin-top: 0;
    }
    .menu_block_item {
      .menu_block_item_span {
        padding-left: 6px!important;
        padding-right: 6px!important;
      } 
    }
  }
  // 1100px 以下
  @media (max-width: 1150px) {
    .header-bottom {
      padding: 10px 20px!important;
      .top_logo {
        width: 109px!important;
        // height: 26px!important;
        object-fit: contain;
      }
    }

    .header-bottom .btn-badge_margin {
      margin-left: 12px;
      margin-right: 12px;
    }
    .search_icon {
      margin-right: 12px!important;
    }
  }
}
</style>
<style lang="scss" src="./default.mobile.scss"></style>
<style lang="scss" src="./new-layout.scss"></style>
