<script lang='ts' setup>
import { GoogleSignInButton, type CredentialResponse } from 'vue3-google-signin'
import "/node_modules/flag-icons/css/flag-icons.min.css";
// import { Base64 } from "js-base64";
import { useMainStore } from '@/store/';
import {
  Close,
} from "@element-plus/icons-vue";

const emit = defineEmits(['cancel']);

const { userApi, shoppingCartApi, collectApi } = useApi()

const sizeMode = inject('sizeMode', 'xxl');

interface loginAdImg{
  mimeType?: String,
  storageType?: String,
  storageKey?: String,
  [key: string]: any, 
}

interface loginAd {
  title?: String,
  remarks?: String,
  advertisementImage?: loginAdImg,
  [key: string]: any, 
}

const props = defineProps({
  loginAdList: Array as PropType<loginAd[]>,
});

const loginForm: any = reactive({
  username: '',
  password: '',
  remember_me: true,
  autoLogin: true,
  type: 'account',
})
const ruleFormRef = ref<FormInstance>()
const loginFormRules = reactive<FormRules>({
  username: [
    { required: true, message: 'Enter your email', trigger: 'blur' },
    {
      type: 'email',
      message: 'Please input correct email',
      trigger: ['blur', 'change'],
    },
  ],
  password: [
    { required: true, message: 'Enter your password', trigger: 'blur' },
  ],
})
const createForm: any = reactive({
  firstName: '',
  lastName: '',
  account: '',
  password: '',
  phone: '',
})
const createFormRules = reactive({
  account: [
    { required: true, message: 'Enter your email', trigger: 'blur' },
    {
      type: 'email',
      message: 'Please input correct email',
      trigger: ['blur', 'change'],
    },
  ],
  password: [
    { required: true, message: 'Enter your password', trigger: 'blur' },
  ],
})

const passwordForm:any = reactive({
  email: ''
})
const passwordFormRules:any = reactive({
  email: [
    { required: true, message: 'Enter your email', trigger: 'blur' },
    {
      type: 'email',
      message: 'Please input correct email',
      trigger: ['blur', 'change'],
    },
  ]
})

// const input = ref('')
const subscribeSelected = ref(true)
let localStorageRemeber = localStorage.getItem('login_remember_password') === 'true' ? true : false
const remember_password = ref(localStorageRemeber)
const value = ref('')
const phonePrefix = ref('')
watch(value,(newVal, oldVal)=>{
  // showLogin.value = 'login'
  // createForm.phone = newVal
  phonePrefix.value = newVal
})
const cities = [
  {
    value: 'Beijing',
    label: 'bj',
  },
  {
    value: 'Shanghai',
    label: 'sh',
  },
  {
    value: 'Nanjing',
    label: 'nj',
  },
  {
    value: 'Chengdu',
    label: 'cd',
  },
  {
    value: 'Shenzhen',
    label: 'sz',
  },
  {
    value: 'xuzhou',
    label: 'xz',
  },
  {
    value: 'taiyuan',
    label: 'ty',
  },
  {
    value: 'hangzhou',
    label: 'hz',
  },
  {
    value: 'changsha',
    label: 'cs',
  },
]
// 显示登录/注册
const showLogin = ref('login')
// 点击注册
const handleCreate = () => {
  showLogin.value = 'register'
}
// 点击登录
const handleLogin = () => {
  showLogin.value = 'login'
}
// 注册
const handleRegister = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      registerLoading.value = true
      let { phone,account } = createForm
      let finPhone = phone;
      if(phone){
        finPhone = phone.replace(phonePrefix.value, phonePrefix.value + ' ')
      }
      userApi
        .createUser({...createForm, phone: finPhone}, {
          subscribeFlag: subscribeSelected.value ? 1 : 0,
        })
        .then((res: any) => {
          console.log('注册的返回值：', res)
          if (res.success) {
            ElMessage.success('Registration successful')
            showLogin.value = 'login'
            identify({$email: account})
          }
        })
        .finally(() => registerLoading.value = false)
    } else {
      setTimeout(()=>{
            var isError= document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
        },100);
      console.log('error submit!')
      return false
    }
  })
}

/**
 * 以下代码误删，参考以下方法调用接口，可优化
 */
const store = useMainStore()
const loginVisible = useState('loginVisible')
// 测试登录按钮
const submitForm = async (formEl: FormInstance | undefined) => {

  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      signLoading.value = true
      const userName = useCookie('user_name',{maxAge: 60 * 60 * 24 * 30})
      const password = useCookie('login_password',{maxAge: 60 * 60 * 24 * 30})
      userApi.userLogin(loginForm).then(async (res: any) => {
        console.log('登录的返回值：', res)
        if (res.token) {
          useCookie('access_token',{maxAge: 60 * 60 * 24 * 30}).value = res.token
          useCookie('conId',{maxAge: 60 * 60 * 24 * 30}).value = res.conId
          await nextTick()
          const storageData = localStorage.getItem('shoppingCartLocalItems');
          const collectLists = JSON.parse(localStorage.getItem('collectLists'))
          syncCollect(collectLists)
          await store.getCurrentUser()
          // await store.getShoppingList()
          if (storageData) {
              const shoppingCartCacheList = JSON.parse(storageData);

              if (shoppingCartCacheList) {
                  const viceRingItems: any[] = [];
                  const mainRingItems: any[] = [];

                  const shoppingCartParams = shoppingCartCacheList.filter(i => i).map(item=>{

                    if (item?.ringKey) {
                      mainRingItems.push(item);
                    }

                    // 副戒先不同步
                    if (!item?.associateRingKey) {
                      return  {
                        goodsId: item.goodsId,
                        ...item.attributeId && {attributeId: item.attributeId},
                        ...item.specificationId && {specificationId: item.specificationId},
                        ...item?.ringKey && {commodityCategory: 3},
                        fontId: item.fontId,
                        graveFont: item.graveFont,
                        quantity: item.quantity,
                        goodsPrice:item.goodsPrice
                      }
                    } else {
                      // 确保对应的主戒存在
                      const filterItem =  shoppingCartCacheList.find((i)=>i?.ringKey == item?.associateRingKey);

                      if (filterItem) {
                          viceRingItems.push(item);
                      }
                    }
                  }).filter(item => item);
                  try {
                    await shoppingCartApi.syncShoppingCart(shoppingCartParams).then(async (res:any)=>{
                      if (res.success) {
                        // 清除购物车缓存
                        localStorage.removeItem('shoppingCartLocalItems');
                        res.data.map((id,key)=>{
                          mainRingItems[key].accessoryCartId = id;
                        })

                        // 添加副戒
                        if (viceRingItems.length > 0) {
                          const viceShoppingCartParams = viceRingItems.map(item=>{

                            // 查找对应的主戒
                            const mainRing = mainRingItems.find((i)=>i?.ringKey == item?.associateRingKey);

                            if (mainRing && mainRing?.accessoryCartId) {
                              return  {
                                goodsId: item.goodsId,
                                ...item.attributeId && {attributeId: item.attributeId},
                                ...item.specificationId && {specificationId: item.specificationId},
                                commodityCategory: 4,
                                accessoryCartId: mainRing?.accessoryCartId,
                                fontId: item.fontId,
                                graveFont: item.graveFont,
                                quantity: item.quantity,
                                goodsPrice:item.goodsPrice
                              }
                            }
                          }).filter(item=>item);
                          await shoppingCartApi.syncShoppingCart(viceShoppingCartParams);
                        }
                      }
                    });
                    
                  } catch(e) {
                    console.error(e);
                  }
                  
              }
          }
          localStorage.setItem('login_remember_password', JSON.stringify(remember_password.value));
          if (remember_password.value) {
            userName.value = loginForm.username
            
            password.value = window.btoa(window.encodeURIComponent(loginForm.password))
            // Base64.encode(loginForm.password)
          } else {
            userName.value = ''
            password.value = ''
          }
          loginVisible.value = false
          nextTick(() => {
            location.reload()
          })
        }
        signLoading.value = false
      }).catch(()=>{
        signLoading.value = false
      })
    } else {
      console.log('error submit!')
      setTimeout(()=>{
            var isError= document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
        },100);
      return false
    }
  })
}
async function syncCollect(collectLists:any){
  if(collectLists){
    let collectIdsParams = collectLists.length ? collectLists.map((e: any) => e.id) : []
    try{
      await collectApi.syncCollect(collectIdsParams).then((res: any) => {
        if(res.success){
          localStorage.removeItem('collectLists');
        }
      })
    }catch(e) {
      console.error(e);
   }
  }
}
const accessToken = useCookie('access_token')
const conId = useCookie('conId')
const faceBookLogin = () => {
  console.debug('faceBookLogin');
  // 2024/3/31 不要在开始检查登录状态，如果用户已删除程序，反正造成无法再次显示登录框  
  FB.login((res) => {
        // 不管用户有没有登录都会有res
        console.log('FB.login', res)
        // facebookPost(res.authResponse.accessToken)
        userApi
          .otherLogin({ token: res.authResponse.accessToken, registerType: 1 })
          .then((res: any) => {
            if (res.success) {
              const collectLists = JSON.parse(localStorage.getItem('collectLists'))
              syncCollect(collectLists)
              console.log(res)
              accessToken.value = res.data.token
              conId.value = res.data.conId
              location.reload()
            }
          })
      }, {scope: 'public_profile,email'});
  // 检查用户登录状态
  // FB.getLoginStatus(function (response) {
  //   console.debug('faceBookLogin', response);
  //   if (response.status !== 'connected') {
  //     // 未登录，fb会让用户登录
  //   } else {
  //       userApi
  //         .otherLogin({ token: response.authResponse.accessToken, registerType: 1 })
  //         .then((res: any) => {
  //           if (res.success) {
  //             const collectLists = JSON.parse(localStorage.getItem('collectLists'))
  //             syncCollect(collectLists)
  //             console.log(res)
  //             accessToken.value = res.data.token
  //             conId.value = res.data.conId
  //             location.reload()
  //           }
  //         });
  //   }
  // })
}

const googleLoginSuccess = (response: CredentialResponse) => {
  const { credential } = response
  console.log('Access Token', credential)
  userApi
    .otherLogin({ token: credential, registerType: 2 })
    .then((res: any) => {
      if (res.success) {
        const collectLists = JSON.parse(localStorage.getItem('collectLists'))
        syncCollect(collectLists)
        console.log(res)
        accessToken.value = res.data.token
        conId.value = res.data.conId
        location.reload()
      }
    })
}
const googleLoginError = () => {
  console.error('Login failed')
}
const retrievePassword = () => {
  showLogin.value = 'retrievePassword'
}
const submitRetrievePassword = (formEl: FormInstance | undefined) => {
  if (!formEl) return
  formEl.validate((valid) => {
    if (valid) {
      console.log('submit!')
      let email = passwordForm.email
      userApi
          .sendChangePassword(email)
          .then((res: any) => {
            if(res.success){
              showLogin.value = 'login'
              ElMessage.success('The email is sent successfully, please pay attention to check the mailbox!')
            }
          })
    } else {
      setTimeout(()=>{
            var isError= document.getElementsByClassName("is-error");
            isError[0].querySelector('input').focus();
        },100);
      console.log('error submit!')
      return false
    }
  })
}
const phoneCodeList = ref([])
const getPhoneCodeList = () => {
  userApi.getPhoneCodeList().then(res=>{
    if(res.success){
      value.value = res.data[1].areaCode;
      countryCode.value = res.data[1].countryAbbreviation.toLowerCase()
      // createForm.phone = res.data[0].areaCode
      phonePrefix.phone = res.data[0].areaCode
      phoneCodeList.value = res.data
    }
  })
}
const countryCode = ref('')
const changeCountryPhone = (e) => {
  countryCode.value = e.countryAbbreviation.toLowerCase()
}

onMounted(() => {
  const username = useCookie('user_name')
  const password = useCookie('login_password')
  // const password = useCookie('login_password')
  // 记住账号密码
  if (username.value && password.value && remember_password.value) {
    loginForm.username = username.value
    loginForm.password = window.decodeURIComponent(window.atob(password.value))
    // Base64.decode(password.value)
  }
  getPhoneCodeList()
})

const signLoading = ref(false);
const registerLoading = ref(false)

</script>

<template>
  <template v-if="['xs'].includes(sizeMode)">
    <div class="loginMbPage">
      <div class="loginMbPage-header">
        <img src="~/assets/close_icon_login.png" alt="" srcset="" @click="emit('cancel')">
      </div>

      <div class="loginMbPage-content">
        <template v-if="showLogin == 'login'">
          <div class="loginMbPage-content__title">{{loginAdList[0]?.title}}</div>
          <div class="loginMbPage-content__subtitle">{{loginAdList[0]?.remarks}}</div>
          <div class="loginMbPage-content__text">
            <span>{{loginAdList[2]?.title}}</span>
            <strong>{{loginAdList[2]?.remarks}}</strong>
          </div>

          <el-form
            ref="ruleFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            class="loginMbPage-content__form"
            status-icon
          >
            <el-form-item prop="username">
              <el-input
                v-model="loginForm.username"
                placeholder="Enter your email"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="loginForm.password"
                placeholder="Enter your password"
              />
            </el-form-item>
          </el-form>

          <div class="loginMbPage-content__formbtns">
            <el-checkbox v-model="remember_password" label="Remember me" />
            <span class="loginMbPage-content__formbtns__Forgot" @click="retrievePassword">
              Forgot your password？
            </span>
          </div>

          <el-button
            :loading="signLoading"
            class="loginMbPage-content__sign"
            @click="submitForm(ruleFormRef)"
          >
            SIGN IN
          </el-button>

          <el-divider class="loginMbPage-content__divider">or sign up using</el-divider>

          <div class="loginMbPage-content__other">
            <div class="loginMbPage-content__other__icon" @click="faceBookLogin">
              <img
                src="~/assets/feekbook_pic.png"
                alt=""
              >
            </div>

            <div class="loginMbPage-content__other__icon" @click="onClickGoogle">
              <img src="~/assets/chrom_pic.png" alt="">
              <div class="loginMbPage-content__other__icon__google">
                <GoogleSignInButton
                  ref="googleBtnRef"
                  type="icon"
                  @success="googleLoginSuccess"
                  @error="googleLoginError"
                ></GoogleSignInButton>
              </div>
            </div>
          </div>

          <div class="loginMbPage-content__tip">
            <span>Don't have an account？</span>
          </div>

          <el-button
            class="loginMbPage-content__create"
            @click="handleCreate()"
          >
            Create Account
          </el-button>
        </template>
        <template v-else-if="showLogin == 'register'">
          <div class="loginMbPage-content__title">Create An Account</div>

          <el-form
            ref="ruleFormRef"
            :model="createForm"
            :rules="createFormRules"
            class="loginMbPage-content__form register-from"
            status-icon
          >
            <el-form-item prop="firstName">
              <el-input
                v-model="createForm.firstName"
                placeholder="First Name"
              />
            </el-form-item>
            <el-form-item prop="lastName">
              <el-input
                v-model="createForm.lastName"
                placeholder="Last Name"
              />
            </el-form-item>
            <el-form-item prop="account">
              <el-input
                v-model="createForm.account"
                placeholder="Enter your email"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                v-model="createForm.password"
                placeholder="Enter your password"
              />
            </el-form-item>
            <el-form-item prop="">
              <div class="loginMbPage-content__form__phoneWrap">
                <el-select
                  v-model="value"
                  placeholder="Select"
                  :default-first-option="true"
                  class="loginMbPage-content__form__phoneWrap__left"
                  popper-class="login-content-select-option"
                >
                  <template #prefix>
                    <span class="fi" :class="'fi-'+ countryCode"></span>
                  </template>
                  <el-option
                    v-for="item in phoneCodeList"
                    :key="item.id"
                    :label="item.areaCode"
                    :value="item.areaCode"
                    @click="changeCountryPhone(item)"
                  >
                    <span class="fi" :class="'fi-'+item.countryAbbreviation.toLowerCase()"></span>
                    <span class="pl-2">{{ item.countryAbbreviation.toUpperCase() }}</span>
                    <span class="login-content-select-option-title">{{ item.areaCode }}</span>
                  </el-option>
                </el-select>

                <el-input
                  v-model="createForm.phone"
                  placeholder="Phone number(Optional)"
                  class="loginMbPage-content__form__phoneWrap__right"
                />
              </div>
            </el-form-item>
          </el-form>

          <div class="loginMbPage-content__formbtns">
            <el-checkbox v-model="subscribeSelected" />
            <div class="loginMbPage-content__formbtns__label">
              Add Me to the MSBLUE VIP list to receive exclusive deals, coupons, and news via email.
            </div>
          </div>

          <el-button
            :loading="signLoading"
            class="loginMbPage-content__sign"
            @click="handleRegister(ruleFormRef)"
          >
            Create My Account 
          </el-button>

          <el-divider class="loginMbPage-content__divider">or sign up using</el-divider>

          <div class="loginMbPage-content__other">
            <div class="loginMbPage-content__other__icon" @click="faceBookLogin">
              <img
                src="~/assets/feekbook_pic.png"
                alt=""
              >
            </div>

            <div class="loginMbPage-content__other__icon" @click="onClickGoogle">
              <img src="~/assets/chrom_pic.png" alt="">
              <div class="loginMbPage-content__other__icon__google">
                <GoogleSignInButton
                  ref="googleBtnRef"
                  type="icon"
                  @success="googleLoginSuccess"
                  @error="googleLoginError"
                ></GoogleSignInButton>
              </div>
            </div>
          </div>

          <div class="loginMbPage-content__tip">
            <span>Already have a MSBLUE account？</span>
            <span class="loginMbPage-content__tip__sign" @click="handleLogin()">Sign In</span>
          </div>
        </template>
        <template v-else-if="showLogin == 'retrievePassword'">
          <div class="loginMbPage-content__forgot">
            <div class="loginMbPage-content__title">Forgot Your Password？</div>
            <div class="loginMbPage-content__forgot__tip">Click "Submit" to receive password reset instructions via email.</div>
          </div>

          <el-form
            ref="ruleFormRef"
            :model="passwordForm"
            :rules="passwordFormRules"
            class="loginMbPage-content__form"
            status-icon
          >
            <el-form-item prop="email">
              <el-input
                v-model="passwordForm.email"
                placeholder="Enter your email"
              />
            </el-form-item>
          </el-form>
          <el-button
            class="loginMbPage-content__forgot__submission"
            @click="submitRetrievePassword(ruleFormRef)"
          >
            Submit
          </el-button>

          <div class="loginMbPage-content__tip">
            <span>Already have a MSBLUE account？</span>
            <span class="loginMbPage-content__tip__sign" @click="handleLogin()">Sign In</span>
          </div>
        </template>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="login-dialog-close" @click="emit('cancel')">
      <el-icon>
        <Close />
      </el-icon>
    </div>
    <div class="login-container">
      <div class="login-img">
        <img
          :src="fileUrl + loginAdList[1]?.advertisementImage[0].storageKey"
          :title="loginAdList[1]?.interlinkage"
          :alt="loginAdList[1]?.advertisementImage[0]?.storageKey"/>
      </div>
      
      <div class="login-content">
        <!-- 登录 -->
        <div
          v-if="showLogin == 'login'"
          class="login-sign"
        >
          <div class="login-content-title">{{loginAdList[0]?.title}}</div>
          <div class="login-content-subtitle">{{loginAdList[0]?.remarks}}</div>
          <div class="login-content-text">
            {{loginAdList[2]?.title}}
            <strong>{{loginAdList[2]?.remarks}}</strong>
          </div>
          <el-form
            ref="ruleFormRef"
            :model="loginForm"
            :rules="loginFormRules"
            class="demo-ruleForm"
            status-icon
          >
            <el-form-item prop="username">
              <el-input
                class="login-content-input"
                v-model="loginForm.username"
                placeholder="Enter your email"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                class="login-content-input"
                type="password"
                v-model="loginForm.password"
                placeholder="Enter your password"
                show-password
              />
            </el-form-item>
          </el-form>
          <div class="login-content-btns">
            <el-checkbox v-model="remember_password" label="Remember me" />
            <!-- <el-switch
              v-model="remember_password"
              class=""
              active-text="Remember me"
              inactive-text=""
              style="--el-switch-on-color: #04a9c7"
            /> -->
            <span @click="retrievePassword">
              Forgot your password？
            </span>
          </div>
          <!-- 验证码，暂时不要 -->
          <!-- <img :src="readomImage" alt=""> -->
          <el-button
            :loading="signLoading"
            class="login-content-solid"
            @click="submitForm(ruleFormRef)"
          >
            SIGN IN
          </el-button>
          <el-divider class="login-content-divider">
            or sign up using
          </el-divider>
          <div class="login-content-other">
            <div @click="faceBookLogin">
              <img
                src="~/assets/feekbook_pic.png"
                alt=""
              >
            </div>

            <!-- <div>
              <img src="~/assets/chrom_pic.png" alt="">
            </div> -->
            <div>
              <GoogleSignInButton
                type="icon"
                @success="googleLoginSuccess"
                @error="googleLoginError"
              ></GoogleSignInButton>
            </div>
          </div>
          <div class="login-content-divider">
            Don't have an account？
          </div>
          <div
            class="login-content-hollow"
            @click="handleCreate()"
          >
            Create Account
          </div>
        </div>
        <!-- 新账号 -->
        <div
          v-else-if="showLogin == 'register'"
          class="login-create"
        >
          <div class="login-content-title">Create an account</div>
          <el-form
            ref="ruleFormRef"
            :model="createForm"
            :rules="createFormRules"
            class="demo-ruleForm"
            status-icon
          >
            <el-form-item prop="firstName">
              <el-input
                class="mt-5 login-content-input"
                v-model="createForm.firstName"
                placeholder="First Name"
              />
            </el-form-item>
            <el-form-item prop="lastName">
              <el-input
                class="login-content-input"
                v-model="createForm.lastName"
                placeholder="Last Name"
              />
            </el-form-item>
            <el-form-item prop="account">
              <el-input
                class="login-content-input"
                v-model="createForm.account"
                placeholder="Enter your email"
              />
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                class="login-content-input"
                v-model="createForm.password"
                placeholder="Enter your password"
              />
            </el-form-item>
            <el-form-item prop="">
              <div class="login-content-selectinput">
                <el-select
                  class="login-content-select"
                  v-model="value"
                  placeholder="Select"
                  :default-first-option="true"
                >
                  <template #prefix>
                    <span class="fi" :class="'fi-'+ countryCode"></span>
                  </template>
                  <el-option
                    v-for="item in phoneCodeList"
                    :key="item.id"
                    :label="item.areaCode"
                    :value="item.areaCode"
                    @click="changeCountryPhone(item)"
                  >
                    <span class="fi" :class="'fi-'+item.countryAbbreviation.toLowerCase()"></span>
                    <span class="pl-2">{{ item.countryAbbreviation.toUpperCase() }}</span>
                    <span 
                      style="
                      float: right;
                      color: var(--el-text-color-secondary);
                      font-size: 18px;
                    ">{{ item.areaCode }}</span>
                  </el-option>
                </el-select>
                <el-input
                  class="login-content-input"
                  v-model="createForm.phone"
                  placeholder="Phone number(Optional)"
                />
              </div>
            </el-form-item>
          </el-form>
          <!-- <el-input class="login-content-input" v-model="input" placeholder="First Name" /> -->
          <!-- <el-input class="login-content-input" v-model="input" placeholder="Last Name" /> -->
          <!-- <el-input class="login-content-input" v-model="input" placeholder="Enter your email" />
          <el-input class="login-content-input" v-model="input" placeholder="Enter your password" />
          <div class="login-content-selectinput">
            <el-select class="login-content-select" v-model="value" placeholder="Select">
              <el-option
                v-for="item in cities"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
                <span
                  style="
                    float: right;
                    color: var(--el-text-color-secondary);
                    font-size: 13px;
                  "
                  >{{ item.value }}</span
                >
              </el-option>
            </el-select>
            <el-input class="login-content-input" v-model="input" placeholder="Phone number(Optional)" />
          </div> -->
          <div class="login-content-btns">
            <!-- <el-switch
              v-model="subscribeSelected"
              class=""
              style="--el-switch-on-color: #04a9c7"
            /> -->
            <el-checkbox v-model="subscribeSelected" />
            <div class="login-content-btns-text">
              Add Me to the MSBLUE VIP list to receive exclusive deals, coupons, and news via email.
            </div>
          </div>
          <el-button
            class="login-content-solid"
            @click="handleRegister(ruleFormRef)"
            :loading="registerLoading"
          >
            Create My Account
          </el-button>
          <el-divider class="login-content-divider">
            or sign up using
          </el-divider>
          <!-- <div class="login-content-other">
            <img
              src="~/assets/facebock_icon.svg"
              alt=""
            >
            <img
              src="~/assets/chrome_icon.svg"
              alt=""
            >
          </div> -->
          <div class="login-content-other">
            <div @click="faceBookLogin">
              <img
                src="~/assets/feekbook_pic.png"
                alt=""
              >
            </div>

            <!-- <div>
              <img src="~/assets/chrom_pic.png" alt="">
            </div> -->
            <div>
              <GoogleSignInButton
                type="icon"
                @success="googleLoginSuccess"
                @error="googleLoginError"
              ></GoogleSignInButton>
            </div>
          </div>
          <div class="login-content-divider">
            Already have a MSBLUE account？ <span @click="handleLogin()">Sign In</span>
          </div>
        </div>
        <!-- 忘记密码 -->
        <div v-else-if="showLogin == 'retrievePassword'" class="retrieve-modal">
          <div class="login-content-title">Forgot your password？</div>
          <div class="login-content-retrieve">Click "Submit" to receive password reset instructions via email.</div>
          <el-form
            ref="ruleFormRef"
            :model="passwordForm"
            :rules="passwordFormRules"
            class="demo-ruleForm"
            status-icon
          >
            <el-form-item prop="email">
              <el-input
                class="mt-7 login-content-input"
                v-model="passwordForm.email"
                placeholder="Enter your email"
              />
            </el-form-item>
          </el-form> 
          <div
            class="login-content-solid mt-8"
            @click="submitRetrievePassword(ruleFormRef)"
          >
            Submit
          </div>
          <div class="login-content-divider pt-5">
            Already have a MSBlue account？ <span @click="handleLogin()">Sign In</span>
          </div>
        </div>
        
      </div>
    </div>
  </template>
</template>

<style scoped lang="scss">
.login-container {
  margin: 32px auto;
  width: 960px;
  height: 640px;
  // background-color: beige;
  display: flex;
  .login-img {
    width: 480px;
    img {
      width: 100%;
      height: 640px;
      object-fit: cover;
      object-position: top;
    }
    // height: 100%;
    // background: url('https://formsv2.soundestlink.com/cdn-cgi/image/fit=scale-down,width=1560/forms/6437fbfed6a40f0016db9bfb')
    //   center center / cover no-repeat;
  }
  .login-content {
    flex: 1;
    padding: 50px 50px 35px 50px;
    &-title {
      text-align: center;
      font-size: 30px;
      font-weight: bold;
      line-height: 30px;
      color: #000000;
    }
    &-subtitle{
      margin-top: 6px;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
      font-family: Century Gothic;
      font-weight: bold;
      color: #000000;
    }
    &-retrieve{
      margin-top: 30px;
      font-size: 14px;
      font-family: Century Gothic;
      color: #000000;
      line-height: 18px; 
    }
    &-text {
      margin: 17px 0 17px 0;
      font-family: Century Gothic;
      font-size: 14px;
      line-height: 24px;
      color: #000000;
    }
    &-input {
      margin: 6px 0;
      height: 32px;
      :deep(.el-input__wrapper) {
        height: 32px;
        box-shadow: 0 0 0;
        border-bottom: 2px solid #0c1636;
        border-radius: 0;
        padding: 6px 0;
      }
    }
    &-selectinput {
      display: flex;
      align-items: center;
      :deep(.el-input__wrapper) {
        padding: 6px 12px;
        border-radius: 0;
        border-bottom: 2px solid #0c1636;
        box-shadow: 0 0 0 !important;
      }
      :deep(.el-select .el-input.is-focus .el-input__wrapper) {
        box-shadow: 0 0 0 !important;
      }
    }
    &-select {
      margin-right: 10px;
    }
    &-btns {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-text {
        font-size: 12px;
        padding-left: 10px;
      }
      span {
        display: block;
        user-select: none;
        color: #04a9c7;
        padding: 0 10px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &-solid,
    &-hollow {
      width: 100%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      margin: 10px 0 -2px 0;
      font-size: 14px;
      &:hover {
        opacity: 0.8;
        cursor: pointer;
      }
    }
    &-solid {
      color: #ffffff;
      background-color: #04a9c7;
    }
    &-hollow {
      color: #fff;
      background-color: #0C1737;
    }
    &-divider {
      font-size: 14px;
      text-align: center;
      color: #000000;
      margin-top: 14px;
      span {
        user-select: none;
        color: #04a9c7;
        padding: 0 10px;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    &-other {
      width: 100%;
      height: 40px;
      display: flex;
      margin-top: 35px;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      img:nth-child(1){
        margin-right: 20px;
      }
    }
  }
  @media (max-width: 1600px) {
  }
  @media (max-width: 1200px) {
  }
  @media (max-width: 1199px) {
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 960px) {
  }
  @media (max-width: 768px) {
    width: 100%;
    margin: 0 auto;
    height: 100vh;
    .login-img {
      display: none;
    }
    .login-content {
      width: 100%;
    }
  }
  @media (max-width: 576px) {
  }
}
.GSignInButton {
  /* This is where you control how the button looks. Be creative! */
  display: inline-block;
  padding: 4px 8px;
  border-radius: 3px;
  background-color: #3c82f7;
  color: #fff;
  box-shadow: 0 3px 0 #0f69ff;
}
:deep(.is-checked) {
  .el-checkbox__inner {
    background: #78D9E1;
    border: 1px solid #78D9E1;
    &::after {
      // display: none;
      position: absolute;
      left: 5px;
    }
  }
  .el-checkbox__label{
    color: #000!important;
  }
}
:deep(.el-form-item) {
  margin-bottom: 20px;
}
:deep(.el-checkbox__input) {
    --el-checkbox-input-height: 16px!important;
    --el-checkbox-input-width: 16px!important;
    // border: 1px solid #000;
}
.retrieve-modal{
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}
</style>

<style scoped lang="scss" src="./loginPopup.mobile.scss"></style>
<style lang="scss">
.body-size-xs {
  .login-content-select-option-title{
    font-size: 0.15rem;
    float: right;
    color: var(--el-text-color-secondary);;
  }
  .login-content-select-option{
    .el-select-dropdown__item {
      font-size: 0.15rem;
      font-family: gothic;
      color: #000000;
      height: 0.48rem;
      line-height: 0.48rem;
      &.selected {
        color: #000000;
        background-color: #d7ecf0;
      }
    }
  }
}
.body-size-sm{

}
.body-size-md{ 
}
</style>