<script setup lang="ts">
import { getFileUrl } from '~/composables/fileUrl';

interface NavMenuItem {
  id: string,
  type: 'title' | 'smallImage' | 'bigImage' | 'imageOnly' | 'html',
  title: string,
  navigationLink?: string,
  linkImage?: { id: string, storageKey: string }[],
  html?: string,
  children?: NavMenuItem[]
}

const props = defineProps({
  list: Array as PropType<NavMenuItem[]>
});

// 合并列表，当列数大于 4 时，每两列合一列
const mixList = computed(() => {
  if (props.list) {
    let length = props.list.length;
    const newList = [] as any[];
    const targetLength = props.list.every(i => i.type === 'bigImage') ? 5 : 4
    for(let i = 0; i < props.list.length; i++) {
      if (length > targetLength) {
        length -= 1;
        newList.push([props.list[ i], props.list[i + 1]])
        i++;
      } else {
        newList.push([props.list[i]]);
      }
    }
    console.debug('newList', newList);
    return newList;
  }
  return props.list;
});

function createLinkProps(item: NavMenuItem) {
  const className: string[] = [`nav-item-${item.type}`];
  if (item.children?.length) {
    className.push('has-children');
  }
  if (item.navigationLink) {
    className.push('has-link');
    if (/https?:/.test(item.navigationLink)) {
      return {
        to: item.navigationLink,
        target: '_blank',
        class: className
      }
    } else {
      return {
        to: item.navigationLink,
        class: className
      }
    }
  }
  return {
    class: className
  };
}

</script>
<template>
  <ul v-if="mixList?.length" class="flex jc_ct menu_ul">
    <template v-for="(mixItem, idx) in mixList" :key="mixItem[0].id">
      <li class="menu_li_wrap flex_s_0">
        <template v-for="item in mixItem" :key="item.id">
          <template v-if="item.type === 'title'">
            <NuxtLink v-bind="createLinkProps(item)" :class="`nav-item-title`">
              {{ item.title }}
            </NuxtLink>
          </template>
          <template v-else-if="item.type === 'smallImage'">
            <NuxtLink v-bind="createLinkProps(item)" :class="`nav-item-small-image`">
              <img v-for="image in item.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
              <span class="nav-item-small-image-title">{{ item.title }}</span>
            </NuxtLink>
          </template>
          <template v-else-if="item.type === 'bigImage'">
            <NuxtLink v-bind="createLinkProps(item)" :class="`nav-item-big-image nav-item-first-image`">
              <img v-for="image in item.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
              <span class="nav-item-sub-title">{{ item.title }}</span>
            </NuxtLink>
          </template>
          <template v-else-if="item.type === 'imageOnly'">
            <NuxtLink v-bind="createLinkProps(item)" :class="`nav-item-image-only`">
              <img v-for="image in item.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
            </NuxtLink>
          </template>
          <template v-else-if="item.type === 'html'">
            <div class="nav-item-html-wrapper">
              <div v-if="item.title" v-bind="createLinkProps(item)" :class="`nav-item-html-title`">
                {{ item.title }}
              </div>
              <div :class="`nav-item-html`" v-html="item.html" />
            </div>
          </template>
          <div v-if="item.children" class="nav-item-children">
            <div :class="subitem.type === 'smallImage' ? 'nav-subitem-img' : 'nav-subitem'" v-for="subitem in item.children" :key="subitem.id">
              <template v-if="subitem.type === 'title'">
                <NuxtLink v-bind="createLinkProps(subitem)" :class="`nav-item-sub-title`">
                  {{ subitem.title }}
                </NuxtLink>
              </template>
              <template v-else-if="subitem.type === 'smallImage'">
                <NuxtLink v-bind="createLinkProps(subitem)" :class="`nav-item-small-image`">
                  <img v-for="image in subitem.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
                  <span class="nav-item-sub-title">{{ subitem.title }}</span>
                </NuxtLink>
              </template>
              <template v-else-if="subitem.type === 'bigImage'">
                <NuxtLink v-bind="createLinkProps(subitem)" :class="`nav-item-big-image`">
                  <img v-for="image in subitem.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
                  <span class="nav-item-sub-title">{{ subitem.title }}</span>
                </NuxtLink>
              </template>
              <template v-else-if="subitem.type === 'imageOnly'">
                <NuxtLink v-bind="createLinkProps(subitem)" :class="`nav-item-image-only`">
                  <img v-for="image in subitem.linkImage" :key="image.id" :src="getFileUrl(image)" decoding="async" loading="lazy" />
                </NuxtLink>
              </template>
              <template v-else-if="subitem.type === 'html'">
                <div class="nav-item-html-wrapper">
                  <div v-if="subitem.title" v-bind="createLinkProps(subitem)" :class="`nav-item-html-title`">
                    {{ subitem.title }}
                  </div>
                  <div :class="`nav-item-html`" v-html="subitem.html" />
                </div>
              </template>
            </div>
          </div>
        </template>
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
  .menu_ul {
    // padding: 0 80px;
    // max-width: 1920px;
    // margin: 0 auto;
    margin: 26px auto 36px;
  }

  .menu_li_wrap{
    // padding: 10px;
    a {
      width: 300px;
      margin: 0 30px;
      @media (max-width: 1550px) {
        width: 240px !important;
      }
      @media (max-width: 1200px) {
        width: 200px !important;
        margin: 0 20px;
      }
    }
  }
  .menu_li_sub_img {
    // max-width: 50vw;
    // display: flex;
    // flex-wrap: wrap;
    &-block {
      margin: 0 10px;
    }
  }
  .menu_li_sub_wrap{
    padding: 10px 0;
  }
  .small_img{
    width: 22px;
    height: 22px;
  }
  .big_img{
    width: 100%;
    max-width: 270px;
    height: fit-content;
  }
  .middle_img{
    width: 100%;
    max-width: 136px;
    height: fit-content;
    margin: 0 auto;
  }
  .middle_img_txt{
    text-align: center;
    font-size: 13px;
    color: #000;
  }
  .list_gap{
    padding: 5px 0;
  }
  .title_wrap{
    border: none;
    border-bottom: 1px solid #000000;
    font-weight: bold;
    padding: 5px 0;
    font-size: 13px;
  }

  .nav-subitem {
    margin-bottom: 18px;
  }
  .nav-subitem-img{
    margin-bottom: 6px;
  }

  .nav-item-title {
    display: block;
    font-size: 14px;
    font-family: gothicb;
    font-weight: bold;
    color: #000000;
    &.has-children {
      border-bottom: 1px solid #000000;
      padding-bottom: 9px;
      margin-bottom: 19px;
      // font-weight: bold;
      // font-size: 13px;
    }
  }
  .nav-item-title:hover{
    font-weight: bold;
    color: #29A2B4;
  }
  .nav-item-sub-title {
    display: block;
    font-size: 14px;
    font-family: gothic;
    font-weight: unset;
    color: #000000;
  }
  .nav-item-sub-title:hover{
    font-weight: bold;
    color: #29A2B4;
  }
  .nav-item-small-image {
    display: flex;
    align-items: center;
    img {
      object-fit: contain;
      width: 24px;
      height: 24px;
      margin-right: 16px;
    }
  }

  .nav-item-big-image.nav-item-first-image {
    width: 280px !important;
    margin: 0 10px !important;
    @media (max-width: 1550px) {
      width: 160px !important;
    }
  }
  .nav-item-big-image {
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
      height: fit-content;
    }
    .nav-item-sub-title {
      margin-top: 9px;
      color: #000000;
    }
  }

  .nav-item-image-only {
    img {
      max-width: 272px;
      width: 100%;
      height: fit-content;
    }
  }

  a.has-link {
    text-decoration: none;
    color: #000;
  }
</style>